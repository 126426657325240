import { useDispatch, useSelector } from "react-redux";
import { calendarChangeMonth, selectRentalCalendar } from "../../../redux/rentalSlice";
import styles from "./calendarHeader.module.scss";


function CalendarHeader() {
	var dispatch = useDispatch();
	var calendarInfo = useSelector(selectRentalCalendar);
	let weekdays = ["M", "T", "O", "T", "F", "L", "S"];
	let months = ["Jan","Feb","Mar","Apr","Maj","Jun","Jul","Aug","Sep","Okt","Nov","Dec"];

	return (
		<div className={styles.main}>
			<div className={styles.month_container}>
				<div
					className={`${styles.month_arrow} ${styles.month_arrow_left} material-icons`}
					onClick={() => dispatch(calendarChangeMonth(-1))}
				>
					chevron_left
				</div>
				<div className={styles.month_name}>
					<span>
						{calendarInfo.currentYear}{" "}
						{months[calendarInfo.currentMonth]}
					</span>
				</div>
				<div
					className={`${styles.month_arrow} ${styles.month_arrow_right} material-icons`}
					onClick={() => dispatch(calendarChangeMonth(1))}
				>
					chevron_right
				</div>
			</div>
			<div className={`${styles.row} ${styles.weekdays}`}>
				{weekdays.map((x, i) => (
					<div className={styles.cell} key={i}>
						{x}
					</div>
				))}
			</div>
		</div>
	);
}

export default CalendarHeader;
