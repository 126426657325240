import { RouteComponentProps } from "react-router-dom";
import { httpService } from "../../../services/httpService";
import styles from "./adminUpdatePassword.module.scss";
import UpdatePasswordForm from "./updatePasswordForm";

interface Props extends RouteComponentProps {}

export default function AdminUpdatePassword(props: Props) {
	let pwToken = httpService.getQueryParam("passwordToken");
	return (
		<main className={styles.wrapper}>
			<h1>Opdater adganskode</h1>
			<UpdatePasswordForm resetPasswordToken={pwToken} />
		</main>
	);
}
