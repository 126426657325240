import React, { FormEvent, useEffect } from "react";
import StatusMessage from "../../../components/common/errorMessage";
import { StatusEnum } from "../../../components/common/errorMessage/StatusMessage";
import SubmitButton from "../../../components/common/submitButton";
import InputField from "../../../components/form/inputField";
import { InputFieldType } from "../../../components/form/inputField/InputField";
import { rentalService } from "../../../services/rentalService";
import styles from "./rentalCancellationForm.module.scss";

interface Props{
	rentalId: number
};

function RentalCancellationForm(props: Props) {
	let [phone, setPhone] = React.useState("");
	let [phoneValidated, setPhoneValidated] = React.useState(false);

	let [errorMessage, setErrorMessage] = React.useState("");
	let [successMessage, setSuccessMessage] = React.useState("");
	let [isTempLockedOut, setIsTempLockedOut] = React.useState(false);
	let [isSubmitting, setIsSubmitting] = React.useState(false);

	let handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setIsSubmitting(true);
		setErrorMessage("");
		setSuccessMessage("");

		rentalService
			.rentals.deleteRental(props.rentalId, phone)
			.then(() => {
				setSuccessMessage("Din booking blev aflyst!");
				setPhone("");
			})
			.catch((error) => setErrorMessage("Det lykkedes ikke at aflyse bookingen. Tjek at du har indtastet korrekt telefonnummer"))
			.finally(() => {
				setIsTempLockedOut(true);
				setIsSubmitting(false);
			});
	};

	useEffect(() => {
		if (isTempLockedOut === false) return;
		let timeout = setTimeout(() => setIsTempLockedOut(false), 3000);
		return () => clearTimeout(timeout);
	}, [isTempLockedOut]);

	return (
		<form onSubmit={handleFormSubmit}>
			<p>For at aflyse en booking bedes du indtaste det tilhørende telefonnummer, som du brugte da du oprettede bookingen.</p>
			<div className={styles.content}>
				<div>
					<div className={styles.input}>
						<InputField
							label="telefonnummer"
							name="phone"
							placeholder="88888888..."
							value={phone}
							type={InputFieldType.text}
							onChangeHandler={(event) => setPhone(event.target.value)}
							setIsValid={(isValid) => setPhoneValidated(isValid)}
						/>
					</div>
				</div>
			</div>

			<div className={styles.content}>
				<div className={`${styles.content_end} ${styles.button}`}>
					<SubmitButton
						disabled={!phoneValidated || isSubmitting}
						submitting={isSubmitting}
						title="Aflys"
					/>
					{!!errorMessage && (
						<StatusMessage
							text={errorMessage}
							status={StatusEnum.error}
						></StatusMessage>
					)}

					{!!successMessage && (
						<StatusMessage
							text={successMessage}
							status={StatusEnum.successDark}
						></StatusMessage>
					)}
				</div>
			</div>
		</form>
	);
}

export default RentalCancellationForm;
