import React from "react";
import ReactDOM from "react-dom";
import "./styles/main.scss";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import ModalWrapper from "./components/modalWrapper";
import Toastr from "./components/toastr/Toastr";

var container = document.getElementById("root");
ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<App />
			<ModalWrapper />
			<Toastr />
		</Provider>
	</React.StrictMode>,
	container
);
