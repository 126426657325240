import { NavLink } from "react-router-dom";
import { routes } from "../../config";
import { ReactComponent as LogoLight } from "./logoLight.svg";
import { ReactComponent as LogoDark } from "./logoDark.svg";
import styles1 from "./dark.module.scss";
import styles2 from "./light.module.scss";
import hamburger from "./hamburger.module.scss";
import dropdown from "./dropdown.module.scss";
import { useEffect, useState } from "react";
import { Button, ButtonTheme } from "../../components/common";
import MobileMenu from "./mobileMenu/MobileMenu";
import { useDispatch, useSelector } from "react-redux";
import {
	selectMobileMenuIsOpen,
	toggleMenu,
} from "../../redux/mobileMenuSlice";
import { selectIsAuthorized } from "../../redux/authSlice";

enum HeaderTheme {
	Dark = "Dark",
	Light = "Light",
}

const styles = {
	Dark: styles1,
	Light: styles2,
};

interface Props {
	theme: HeaderTheme;
	frontpage: Boolean;
}

function Header(props: Props) {
	const [theme, setTheme] = useState(props.theme);
	const [isSmall, setIsSmall] = useState(false);

	const dispatch = useDispatch();
	const isAuthorized = useSelector(selectIsAuthorized);
	const mobileMenuIsOpen = useSelector(selectMobileMenuIsOpen);

	// useEffect(() => {
	// 	window.addEventListener("scroll", resizeHeaderOnScroll);
	// });

	// const resizeHeaderOnScroll = () => {
	// 	const distanceY =
	// 		window.pageYOffset || document.documentElement.scrollTop;
	// 	const headerEl = document.querySelector("header");

	// 	if (headerEl != null) {
	// 		if (distanceY > 100) {
	// 			if (props.frontpage && theme == HeaderTheme.Light)
	// 				setTheme(HeaderTheme.Dark);
	// 			setIsSmall(true);
	// 		} else {
	// 			if (props.frontpage && theme == HeaderTheme.Dark)
	// 				setTheme(HeaderTheme.Light);
	// 			setIsSmall(false);
	// 		}
	// 	}
	// };

	const onMenuClick = () => {
		dispatch(toggleMenu({ isOpen: !mobileMenuIsOpen }));

		!mobileMenuIsOpen
			? (document.body.style.overflow = "hidden")
			: (document.body.style.overflow = "visible");
	};

	return (
		<header
			className={`${styles[theme].header} ${
				isSmall && styles[theme].smaller
			}`}
		>
			<div className={styles[theme].wrapper}>
				<div>
					<NavLink to={routes.forside}>
						{theme === HeaderTheme.Light && <LogoLight />}
						{theme === HeaderTheme.Dark && <LogoDark />}
					</NavLink>
				</div>
				<div className={`hide-sm`}>
					<nav>
						<div className={dropdown.dropdown}>
							<div className={`${styles[theme].link}`}>
								Undervisning
								<span
									className={`material-icons ${styles[theme].icon}`}
								>
									expand_more
								</span>
							</div>
							<ul className={dropdown.list} aria-label="submenu">
								<li>
									<NavLink to={routes.kiteIntro}>
										Kite-intro
									</NavLink>
								</li>
								<li>
									<NavLink to={routes.kitesurfing}>
										Foil-kitesurfing
									</NavLink>
								</li>
								<li>
									<NavLink to={routes.windsurfing}>
										Windsurfing
									</NavLink>
								</li>
								<li>
									<NavLink to={routes.garnfiskeri}>
										Garnfiskeri
									</NavLink>
								</li>
								<li>
									<NavLink to={routes.selvforsvar}>
										Selvforsvar
									</NavLink>
								</li>
							</ul>
						</div>

						<div className={dropdown.dropdown}>
							<div className={`${styles[theme].link}`}>
								Udlejning
								<span
									className={`material-icons ${styles[theme].icon}`}
								>
									expand_more
								</span>
							</div>
							<ul className={dropdown.list} aria-label="submenu">
								<li>
									<NavLink to={routes.barrelSauna}>
										Sauna tønde
									</NavLink>
								</li>
								<li>
									<NavLink to={routes.vildmarksbad}>
										Vildmarksbad
									</NavLink>
								</li>
								<li>
									<NavLink to={routes.shelter}>
										Shelter
									</NavLink>
								</li>
								<li>
									<NavLink to={routes.kajak}>Kajak</NavLink>
								</li>
								<li>
									<NavLink to={routes.kano}>Kano</NavLink>
								</li>
								<li>
									<NavLink to={routes.jolle}>Jolle</NavLink>
								</li>
								<li>
									<NavLink to={routes.paddle}>
										Paddle/SUP
									</NavLink>
								</li>
								<li>
									<NavLink to={routes.trailer}>
										Trailer
									</NavLink>
								</li>
								<li>
									<NavLink to={routes.baadtrailer}>
										Bådtrailer
									</NavLink>
								</li>
								<li>
									<NavLink to={routes.sikkerhed}>
										Sikkerhed
									</NavLink>
								</li>
							</ul>
						</div>

						<NavLink
							className={styles[theme].link}
							activeClassName={styles[theme].m__active}
							to={routes.raadgivning}
						>
							Rådgivning
						</NavLink>

						<div className={dropdown.dropdown}>
							<div className={`${styles[theme].link}`}>
								Foredrag
								<span
									className={`material-icons ${styles[theme].icon}`}
								>
									expand_more
								</span>
							</div>
							<ul className={dropdown.list} aria-label="submenu">
								<li>
									<NavLink to={routes.foredrag}>
										Foredrag
									</NavLink>
								</li>
								<li>
									<NavLink to={routes.absurd}>Absurd</NavLink>
								</li>
							</ul>
						</div>

						<NavLink
							className={styles[theme].link}
							activeClassName={styles[theme].m__active}
							to={routes.omOs}
						>
							Om os
						</NavLink>
						{!!isAuthorized && (
							<NavLink
								className={styles[theme].link}
								activeClassName={styles[theme].m__active}
								to={routes.adminMain}
							>
								Admin
							</NavLink>
						)}
					</nav>
				</div>
				<div className={`hide-sm`}>
					{theme === HeaderTheme.Light && (
						<Button
							path={routes.udlejningBooking}
							text="Book"
							theme={ButtonTheme.Transparent}
						></Button>
					)}
					{theme === HeaderTheme.Dark && (
						<Button
							path={routes.udlejningBooking}
							text="Book"
							theme={ButtonTheme.Gradient}
						></Button>
					)}
				</div>
				<div
					className={`${hamburger.button} show-sm`}
					onClick={onMenuClick}
				>
					<span
						className={`material-icons ${hamburger.icon} ${
							mobileMenuIsOpen && hamburger.iconOpen
						}`}
					>
						{!mobileMenuIsOpen ? "menu" : "close"}
					</span>
				</div>
			</div>

			<MobileMenu
				isOpen={mobileMenuIsOpen}
				isAuthorized={isAuthorized}
			></MobileMenu>
		</header>
	);
}

export { Header, HeaderTheme };
