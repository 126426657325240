import { apiBaseUrl } from "../config/apiConfig";
import { AuthUserState, localStorageAuthUserKey } from "../redux/authSlice";
import { ErrorApiResponse } from "./apiModels/response/ErrorApiResponse";
import { ApiException } from "./exceptions";

export const httpService = {
	getQueryParam: (name: string): string => {
		let searchParams = new URLSearchParams(window.location.search);
		return searchParams.get(name) || "";
	},

	get: async <T>(url: string): Promise<T> => {
		return await request<T>(url, "GET");
	},

	post: async <T>(url: string, body: object): Promise<T> => {
		return await request<T>(url, "POST", body);
	},

	put: async <T>(url: string, body: object): Promise<T> => {
		return await request<T>(url, "PUT", body);
	},

	delete: async <T>(url: string): Promise<T> => {
		return await request<T>(url, "DELETE");
	}
};

const getBearerToken = () =>{
	var userAuthStateString = localStorage.getItem(localStorageAuthUserKey);
	if(userAuthStateString == null)
		return null;

	var authUserObj = JSON.parse(userAuthStateString) as AuthUserState;
	return authUserObj.token;
}

const request = async <T>(url: string, method: string, body?: Object): Promise<T> => {
	var headers: HeadersInit  = {
		"Content-Type": "application/json"
	};
	var token = getBearerToken();
	if(!!token) headers["Authorization"] = `Bearer ${token}`;

	var response = await fetch(`${apiBaseUrl}${url}`, {
		method: method,
		cache: "no-cache",
		headers: headers,
		body: !!body ? JSON.stringify(body) : null
	});
	if (response.status > 299) {
		var apiErrorObj = await response.json() as ErrorApiResponse;
		throw new ApiException(apiErrorObj.errorCode, apiErrorObj.errorMessage);
	}

	var jsonResponse = await response.json().catch(() => null) as T;
	return jsonResponse;
}
