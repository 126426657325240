import { EditableRentalProduct } from "../../../../../components/common/editableStuff";
import { RentalProductApiResponse } from "../../../../../services/apiModels/response/RentalProductApiResponse";
import styles from "./rentalProduct.module.scss";

interface Props {
	product: RentalProductApiResponse
};

function RentalProduct(props: Props) {
	return (
		<div className={styles.main}>
			<EditableRentalProduct productId={props.product.id}>
				<h3>{props.product.name}</h3>
				<p>
					<span className={styles.heading}>På lager:</span>
					<span className={styles.value}>{props.product.amountInStock}</span>
				</p>
			</EditableRentalProduct>
		</div>
	);
}

export default RentalProduct;
