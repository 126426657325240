import React, { FormEvent, useEffect } from "react";
import styles from "./loginForm.module.scss";
import InputField from "../inputField";
import SubmitButton from "../../common/submitButton";
import StatusMessage from "../../common/errorMessage";
import { StatusEnum } from "../../common/errorMessage/StatusMessage";
import { InputFieldType } from "../inputField/InputField";
import { useDispatch, useSelector } from "react-redux";
import { loginAction, selectAuthState } from "../../../redux/authSlice";
import { LoadingStatusEnum } from "../../../redux/loadingStatusEnum";
import { Link } from "react-router-dom";
import { routes } from "../../../config";

function LoginForm() {
	let [email, setEmail] = React.useState("");
	let [password, setPassword] = React.useState("");
	let [emailValidated, setEmailValidated] = React.useState(false);
	let [passwordValidated, setPasswordValidated] = React.useState(false);
	let [isTempLockedOut, setIsTempLockedOut] = React.useState(false); // used to avoid spamming send

	const dispatch = useDispatch();
	const authState = useSelector(selectAuthState);

	let handleLogin = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		dispatch(loginAction(email, password));
	};

	useEffect(() => {
		if (
			authState.loadingStatus === LoadingStatusEnum.Loading ||
			(!authState.errorMessage && !authState.user) ||
			!!isTempLockedOut
		)
			return;

		setIsTempLockedOut(true);
	}, [authState.loadingStatus]);

	useEffect(() => {
		if (isTempLockedOut === false) return;

		let timeout = setTimeout(() => {
			setIsTempLockedOut(false);
		}, 3000);

		return () => clearTimeout(timeout);
	}, [isTempLockedOut]);

	let isValidated = () =>
		emailValidated && passwordValidated && !isTempLockedOut;

	return (
		<div className={styles.main}>
			<form onSubmit={handleLogin}>
				<div className={styles.inputDiv}>
					<InputField
						label="Email"
						name="email"
						placeholder="flemming123@gmail.com"
						value={email}
						type={InputFieldType.email}
						onChangeHandler={(event) =>
							setEmail(event.target.value)
						}
						setIsValid={(isValid) => setEmailValidated(isValid)}
					/>
				</div>
				<div className={styles.inputDiv}>
					<InputField
						label="Adgangskode"
						name="password"
						value={password}
						type={InputFieldType.password}
						onChangeHandler={(event) =>
							setPassword(event.target.value)
						}
						setIsValid={(isValid) => setPasswordValidated(isValid)}
					/>
				</div>
				<div className={styles.button}>
					<SubmitButton
						disabled={!isValidated() || authState.loadingStatus === LoadingStatusEnum.Loading}
						submitting={authState.loadingStatus === LoadingStatusEnum.Loading}
						title="Send"
					/>
				</div>
				<div>
					{!!authState.errorMessage && (
						<StatusMessage
							text={authState.errorMessage}
							status={StatusEnum.error}
						></StatusMessage>
					)}
				</div>
				<div>
					<p>Glemt adgangskode? Klik <Link to={routes.glemtAdgangskode}>her</Link> for at for at lave en ny</p>
				</div>
			</form>
		</div>
	);
}

export default LoginForm;
