import React, { FormEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Calendar from "../../components/calendar";
import { Spinner } from "../../components/common";
import { EditableContent } from "../../components/common/editableStuff";
import { SpinnerTheme } from "../../components/common/spinner/Spinner";
import SubmitButton from "../../components/common/submitButton";
import SelectField from "../../components/form/select";
import { selectContentPageSection, useContentPage } from "../../redux/contentPagesSlice";
import { LoadingStatusEnum } from "../../redux/loadingStatusEnum";
import { openModal_createRental } from "../../redux/modalSlice";
import { loadRentals, rentalSetSelectedProduct, selectAvailableProductsByDate, selectRentalCalendar, selectRentalSelectedProducts, useRentalProducts } from "../../redux/rentalSlice";
import { parseDate } from "../../utility";
import styles from "./rental.module.scss";

interface Props {}

function Rental(props: Props) {
	useContentPage("booking");
	const cp_section = useSelector(selectContentPageSection("booking", "intro"));

	const dispatch = useDispatch();
	const rentalProducts = useRentalProducts();
	const {selectedStartDate, selectedEndDate, currentMonth, currentYear} = useSelector(selectRentalCalendar);
	const selectedProducts = useSelector(selectRentalSelectedProducts);
	const currentMonthDaysAvailableProducts = useSelector(selectAvailableProductsByDate(
		new Date(currentYear, currentMonth-1),
		new Date(currentYear, currentMonth+2, 0)));

	const unavailableMonthDaysForSelectedProducts = currentMonthDaysAvailableProducts
		.filter(x =>
			selectedProducts.some(selectedProduct => {
				var availableProductMatch = x.availableProducts.find(ap => ap.productId === selectedProduct.productId)!;
				return availableProductMatch.amount < selectedProduct.amount
		}))
		.map(x => parseDate(x.date));

	useEffect(() => {dispatch(loadRentals());}, []);

	const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		dispatch(
			openModal_createRental()
		);
	};

	const isValidated = () =>
		selectedProducts.some(item => item.amount > 0) &&
		!!selectedStartDate &&
		!!selectedEndDate;

	const onProductInputChange = (id: number, amount: number) =>
		dispatch(rentalSetSelectedProduct({id, amount: amount}));

	const getProductInputValue = (id: number) => {
		var p = selectedProducts.find(x => x.productId === id);
		return p?.amount ?? 0;
	}

	return (
		<section>
			<div className={styles.intro}>
				<div className={styles.wrapper}>
					<EditableContent
						pageKey="booking"
						sectionKey="intro"
					>
						<h1 className={styles.h1}>{cp_section?.title}</h1>
						<div className={styles.introduction} dangerouslySetInnerHTML={{__html: cp_section?.content ?? ""}} ></div>
					</EditableContent>
				</div>
			</div>

			<div className={styles.periode}>
				<div className={styles.wrapper}>
					<form onSubmit={handleFormSubmit}>
						<div>
							<h3 className={styles.h4}>Udstyr</h3>
							{rentalProducts.loadingStatus === LoadingStatusEnum.Loading ? (
								<Spinner theme={SpinnerTheme.Dark} />
							) : (
								<div className={styles.products}>
									{rentalProducts.products.map((p) => (
										<div className={styles.productDiv} key={p.id}>
											<SelectField
												label={p.namePlural}
												name={p.name}
												onChangeHandler={value => onProductInputChange(p.id, parseInt(value))}
												value={getProductInputValue(p.id)}
												options={Array.from({length: p.amountInStock+1}, (item, i) => i).map(option =>
													{return {title: option.toString(), value: option}})}
											/>
										</div>
									))}
								</div>
							)}
						</div>
						<div className={styles.calendar}>
							<h3 className={styles.h4}>Periode</h3>
							<Calendar disabled={!selectedProducts.some(item => item.amount > 0)} disabledDates={unavailableMonthDaysForSelectedProducts} />
						</div>

						<div>
							<SubmitButton
								disabled={!isValidated()}
								title="Fortsæt" />
						</div>
					</form>
				</div>
			</div>
		</section>
	);
}

export default Rental;
