import { ChangeEvent, useEffect, useState } from "react";
import styles from "./textarea.module.scss";
import Label from "../label";

interface Props {
	errorMessage?: string;
	value?: string;
	minLength?: number;
	maxLength?: number;
	label: string;
	name: string;
	placeholder?: string;
	onChangeHandler: (event: ChangeEvent<HTMLTextAreaElement>) => void;
	setIsValid?: (isValid: boolean) => void;
}

const validateText = (value: string, minLength: number, maxLength: number) =>
	value.length >= minLength && value.length <= maxLength;

function Textarea(props: Props) {
	const [isValid, setIsValid] = useState(false);

	// trigger an initial validation. otherwise it wont happen before onchange
	useEffect(() => {
		validate(props.value ?? "");
	}, []);

	const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
		props.onChangeHandler(event);
		validate(event.target.value);
	};

	const validate = (value: string) => {
		let tmpIsValid = validateText(
			value,
			props.minLength ?? 1,
			props.maxLength ?? 9999999
		);

		if (tmpIsValid !== isValid) {
			setIsValid(tmpIsValid);
			props.setIsValid && props.setIsValid(tmpIsValid);
		}
	};

	return (
		<div className={styles.field}>
			<Label inputName={props.name} label={props.label}></Label>
			<textarea
				className={styles.textarea}
				id={props.name}
				name={props.name}
				placeholder={props.placeholder}
				value={props.value || ""}
				onChange={onChange}
			/>
		</div>
	);
}

export default Textarea;
