import { useDispatch, useSelector } from "react-redux";
import { selectIsAuthorized } from "../../../../redux/authSlice";
import { openModal_contentPage } from "../../../../redux/modalSlice";
import styles from "../editableStuff.module.scss";

interface Props {
	children: React.ReactNode;
	pageKey: string;
	sectionKey: string;
}

function EditableContent(props: Props) {
	const isAuthorized = useSelector(selectIsAuthorized);

	var dispatch = useDispatch();

	const handleOnClick = () =>
		dispatch(
			openModal_contentPage({
				body: {
					pageKey: props.pageKey,
					pageSectionKey: props.sectionKey,
				},
			})
		);

	return (
		<div className={styles.editableContent}>
			{!!isAuthorized && (
				<span
					onClick={handleOnClick}
					title="Ændr tekst"
					className={`${styles.button} material-icons`}
				>
					edit
				</span>
			)}
			{props.children}
		</div>
	);
}

export default EditableContent;
