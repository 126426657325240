/**
 * Convert a Date object to yyyy-MM-dd string
 */
export const yyyyMMdd = (d: Date) =>{
	// we have to use leading 0's before days and months or apple devices will shit itself
	var yearStr = d.getFullYear();
	var monthStr = ("0" + (d.getMonth()+1)).slice(-2);
	var dateStr = ("0" + d.getDate()).slice(-2);
	let finalDateString = `${yearStr}-${monthStr}-${dateStr}`;
	return finalDateString;
};

/**
 * Convert a Date object to dd-MM-yyyy string
 */
export const ddMMyyyy = (d: Date) =>{
	let dateStr = `${d.getDate()}/${d.getMonth()+1}-${d.getFullYear()}`;
	return dateStr;
};

/**
 * Get amount of days between two days. rounded down
 */
 export const daysBetween = (from: Date, to: Date) =>
	Math.floor((to.getTime()-from.getTime())/(1000*60*60*24));

/**
 * Get the date part of a Date object without time.
 * The time (hour, minutes, seconds, ms) are stripped from the date.
 */
export const date = (d: Date) => new Date(d.getFullYear(), d.getMonth(), d.getDate());

/**
 * same as the date(d: Date) method except it also calls getTime()
 */
export const dateMs = (d: Date) => date(d).getTime();

/**
 * Parse a yyyy-MM-dd string to a Date object.
 * Note that this does not respect hour
 */
export const parseDate = (d: string) => new Date(Date.parse(d));

/**
* Add years to an existing Date object
*/
export const addYears = (d: Date, amount: number): Date => {
	d.setFullYear(d.getFullYear()+amount);
	return d;
}

/**
 * Add days to an existing Date object
 */
export const addDays = (d: Date, amount: number): Date => {
	d.setDate(d.getDate()+amount);
	return d;
}

/**
 * Add days to an existing Date object
 */
 export const daysArray = (start: Date, end: Date): Date[] => {
	let daysArray: Date[] = [];
	let d = date(start);
	while(d <= date(end)){
		daysArray.push(date(d));
		addDays(d, 1);
	}

	return daysArray;
}


