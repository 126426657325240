import styles from "./top.module.scss";

interface Props {
	heading?: string;
	image?: string;
	imageSize?: "small" | "regular";
	element: React.RefObject<HTMLDivElement>;
}

function Top(props: Props) {
	const onButtonClick = () => {
		if (props.element && props.element.current) {
			window.scroll({
				top: props.element.current.offsetTop - 90,
				behavior: "smooth",
			});
		}
	};

	return (
		<section className={styles.top}>
			<div className={styles.wrapper}>
				<h1 className={styles.heading}>{props.heading}</h1>

				<button className={styles.button} onClick={onButtonClick}>
					<span className={`material-icons ${styles.icon}`}>
						expand_more
					</span>
				</button>

				{props.image && (
					<img
						className={`${styles.image} ${
							props.imageSize === "small" ? styles.imageSmall : ""
						}`}
						src={props.image}
						alt=""
					/>
				)}
			</div>
		</section>
	);
}

export default Top;
