import { ReactComponent as Wave } from "./wave.svg";
import airplane from "./svaevefly.png";
import styles from "./footer.module.scss";

function Footer() {
	return (
		<footer className={styles.footer}>
			<div className={styles.wave}>
				<Wave />
			</div>

			<section className={styles.content}>
				<div className={styles.column}>
					<h3 className={styles.heading}>Kontakt</h3>
					<address className={styles.column}>
						<span>
							<span>MobilePay: </span>
							23 24 41 71
						</span>
						<a className={styles.link} href="tel:23244171">
							<span className={`material-icons ${styles.icon}`}>
								phone
							</span>
							23 24 41 71
						</a>
						<a
							className={styles.link}
							href="mailto:mail@aarhusvandsportscenter.dk"
						>
							<span className={`material-icons ${styles.icon}`}>
								email
							</span>
							mail@aarhusvandsportscenter.dk
						</a>
					</address>
				</div>

				<div className={styles.column}>
					<h3 className={styles.heading}>Bank</h3>
					<span>Reg: 5074</span>
					<span>Konto: 1755156</span>
					<span>MobilePay: 23 24 41 71</span>
				</div>

				<div className={`${styles.column} ${styles.m_end}`}>
					<img
						src={airplane}
						className={styles.airplane}
						alt="airplane"
					/>
				</div>
			</section>
		</footer>
	);
}

export default Footer;
