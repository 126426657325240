import { Header, HeaderTheme } from "./components/header/Header";
import Footer from "./components/footer/Footer";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Subpage from "./pages/subpage/Subpage";
import {
	genericRoutesComponentsMap,
	routes
} from "./config/routes";
import Home from "./pages/home";
import NotFound from "./pages/notFound";
import Contact from "./pages/contact";
import AdminLogin from "./pages/admin/adminLogin";
import AdminMain from "./pages/admin/adminMain";
import RentalProducts from "./pages/admin/rentalProducts";
import Rental from "./pages/rental";
import AdminRentals from "./pages/admin/adminRentals";
import RentalCancellation from "./pages/rentalCancellation";
import AdminUpdatePassword from "./pages/admin/adminUpdatePassword";
import AdminForgotPassword from "./pages/admin/adminForgotPassword";
import RentalFinished from "./pages/rentalFinished";

const App = () => {
	return (
		<BrowserRouter>
			<Header theme={HeaderTheme.Dark} frontpage={true} />
			<Switch>
				{genericRoutesComponentsMap.map((x) => (
					<Route
						path={x.path}
						key={x.pageKey}
						exact={true}
						render={() => <Subpage
							pageKey={x.pageKey}
							productId={x.productId}
							secondaryProductId={x.secondaryProductId}
							disableTopSection={x.disableTopSection ?? false}
							disableImg={x.disableImg ?? false}
							image={x.image}
							smallImg={x.smallImg ?? false}
							/>
						}
					/>
				))}

				<Route
					path={routes.forside}
					render={() => <Home pageKey={"forside"} />}
					exact={true}
				/>
				<Route
					path={routes.udlejningBooking}
					render={() => <Rental />}
					exact={true}
				/>
				<Route
					path={routes.udlejningAflys}
					render={() => <RentalCancellation />}
					exact={true}
				/>

				<Route
					path={routes.udlejningDone}
					render={() => <RentalFinished />}
					exact={true}
				/>

				<Route
					path={routes.kontakt}
					render={() => <Contact />}
					exact={true}
				/>
				<Route
					path={routes.logInd}
					render={(props) => <AdminLogin {...props} />}
					exact={true}
				/>

				<Route
					path={routes.opdaterAdgangskode}
					render={(props) => <AdminUpdatePassword {...props} />}
					exact={true}
				/>

				<Route
					path={routes.glemtAdgangskode}
					render={(props) => <AdminForgotPassword {...props} />}
					exact={true}
				/>

				<Route
					path={routes.adminMain}
					render={(props) => <AdminMain {...props} />}
					exact={true}
				/>
				<Route
					path={routes.adminRentalProducts}
					render={(props) => <RentalProducts {...props} />}
					exact={true}
				/>
				<Route
					path={routes.adminRentals}
					render={(props) => <AdminRentals {...props} />}
					exact={true}
				/>
				<Route
					render={() => <NotFound />}
				/>
			</Switch>
			<Footer />
		</BrowserRouter>
	);
};

export default App;
