import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { calendarSetSelectedEndDate, calendarSetSelectedStartDate, selectRentalCalendar } from "../../../redux/rentalSlice";
import { dateMs, daysArray } from "../../../utility";
import StatusMessage from "../../common/errorMessage";
import { StatusEnum } from "../../common/errorMessage/StatusMessage";
import styles from "./calendarBody.module.scss";

interface Props{
	disabledDates: Date[];
}

function CalendarBody(props: Props) {
	const today = new Date();
	const dispatch = useDispatch();
	const [errorMessage, setErrorMessage] = useState("");
	const {selectedStartDate, selectedEndDate, currentMonth, currentYear} = useSelector(selectRentalCalendar);

	useEffect(() => {
		if (!errorMessage) return;

		let timeout = setTimeout(() => {
			setErrorMessage("");
		}, 3000);

		return () => clearTimeout(timeout);
	}, [errorMessage]);

	// this is used to counter a bug where you can choose a date range before increasing the amount of chosen
	// products which would cause those dates to be invalid.
	useEffect(() => {
		if(selectedStartDate == null)
			return;

		if(!checkIfAnySelectedDateIsDisabled(selectedStartDate, selectedEndDate ?? selectedStartDate))
			return;

		dispatch(calendarSetSelectedStartDate(null));
		dispatch(calendarSetSelectedEndDate(null));
	}, [props.disabledDates])

	const selectDate = (d: Date) => {
		if(dateMs(d) < dateMs(today))
			return;

		if(checkIfIsDisabled(d))
			return;

		if(selectedEndDate != null){
			dispatch(calendarSetSelectedStartDate(d));
			dispatch(calendarSetSelectedEndDate(null));
		}
		else if(selectedStartDate == null || dateMs(d) < dateMs(selectedStartDate)){
			dispatch(calendarSetSelectedStartDate(d));
		}
		else if(selectedStartDate != null && selectedEndDate == null){
			// check that date range doesnt span over disabled days
			if(daysArray(selectedStartDate, d).some(x => checkIfIsDisabled(x))){
				setErrorMessage("Du kan ikke vælge en periode der overlapper med ugyldige datoer.");
				return;
			}

			dispatch(calendarSetSelectedEndDate(d))
		}
	}

	const checkIfAnySelectedDateIsDisabled = (start: Date, end: Date) =>
		daysArray(start, end).some(selectedDate => checkIfIsDisabled(selectedDate));

	const checkIfIsDisabled = (day: Date) =>
		!!props.disabledDates.some(x => dateMs(x) === dateMs(day)) ||
		dateMs(day) < dateMs(today);

	const getMonday = (d: Date) => {
		d = new Date(d);
		var day = d.getDay(),
			diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
		return new Date(d.setDate(diff));
	};

	const renderCells = () => {
		const monthStart = new Date(currentYear, currentMonth);
		const monthEnd = new Date(currentYear, currentMonth + 1, 0);
		const startDate = getMonday(monthStart);
		const endDate = new Date(getMonday(monthEnd).getTime() + 518400000); // 518400000 = 6 days

		let rows = [];
		let days: JSX.Element[] = [];
		let day = startDate;

		while (day <= endDate) {
			for (let i = 0; i < 7; i++) {
				let cloneDay = day; // avoid using "day" which will be overwritten for each iteration because of scope

				let isDisabled = checkIfIsDisabled(day);
				let isToday = dateMs(day) === dateMs(today);

				let isSelected = !!selectedStartDate && (
					(dateMs(day) === dateMs(selectedStartDate)) ||
					(dateMs(day) >= dateMs(selectedStartDate) && !!selectedEndDate && dateMs(day) <= dateMs(selectedEndDate)));

				let cellClasses = "";
				if(isDisabled) cellClasses += ` ${styles.disabled}`;
				// if(isToday) cellClasses += ` ${styles.today}`;
				if(isSelected) cellClasses += ` ${styles.selected}`;
				if(!!selectedStartDate && dateMs(selectedStartDate) === dateMs(day)) cellClasses += ` ${styles.selectedStart}`;
				if(!!selectedEndDate && dateMs(selectedEndDate) === dateMs(day)) cellClasses += ` ${styles.selectedEnd}`;

				days.push(
					<div
						className={`${styles.cell} ${cellClasses}`}
						key={day.getTime()}
						onClick={() => selectDate(cloneDay)}
					>
						<span className={styles.date_number}>{day.getDate()}</span>
					</div>
				);
				day = new Date(day.getTime() + 86400000); // 86400000 = 1 day
			}
			rows.push(
				<div className={styles.weekly_row} key={day.getTime()}>
					{days}
				</div>
			);
			days = [];
		}
		return <div>{rows}</div>;
	};

	return (
		<div className={styles.main}>
			<div className={styles.calendar_body}>{renderCells()}</div>
			<div className={styles.error}>
				<StatusMessage status={StatusEnum.error} text={errorMessage} />
			</div>
		</div>
	);
}

export default CalendarBody;