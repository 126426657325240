import { FormEvent, useState } from "react";
import { useDispatch } from "react-redux";
import SubmitButton from "../../../../../components/common/submitButton";
import SelectField from "../../../../../components/form/select";
import { updateRentalDetailed } from "../../../../../redux/rentalSlice";
import { TranslatePaymentMethod } from "../../../../../services/apiModels/enums/RentalPaymentMethodEnum";
import { RentalCategoryApiResponse } from "../../../../../services/apiModels/response/RentalCategoryApiResponse";
import { RentalDetailedApiResponse } from "../../../../../services/apiModels/response/RentalDetailedApiResponse";
import { rentalService } from "../../../../../services/rentalService";
import {
	daysBetween,
	ddMMyyyy,
	parseDate,
} from "../../../../../utility/dateUtils";
import DeleteRentalButton from "./deleteRentalButton";
import styles from "./rentalRow.module.scss";

interface Props {
	rental: RentalDetailedApiResponse;
	categories: RentalCategoryApiResponse[];
}

function RentalRow(props: Props) {
	const dispatch = useDispatch();
	const rental = props.rental;
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [comment, setComment] = useState(rental.adminComment ?? "");
	const [categoryId, setCategoryId] = useState(rental.categoryId);
	const currentCategory = props.categories.find((x) => x.id === categoryId);

	const handleUpdateFormSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setIsSubmitting(true);

		rentalService.rentals
			.updateRental(rental.id, { comment, categoryId })
			.then(() =>
				dispatch(
					updateRentalDetailed({ id: rental.id, categoryId, comment })
				)
			)
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				setIsSubmitting(false);
			});
	};

	const categoryStyling =
		currentCategory == null || currentCategory.id === 1
			? {}
			: {
					backgroundColor: `${currentCategory!.colorCode}`,
					color: "white",
			  };

	return (
		<div className={styles.table_row} style={categoryStyling}>
			<div className={styles.table_cell}>{rental.id}</div>
			<div className={styles.table_cell}>
				<p>{rental.fullName}</p>
				<p>{rental.phone}</p>
				<p>{rental.emailAddress}</p>
			</div>
			<div className={styles.table_cell}>
				{rental.items.map((item) => (
					<div key={item.productId}>
						<p>
							{item.productName}: {item.count}
						</p>
					</div>
				))}
			</div>
			<div className={styles.table_cell}>
				<p>
					{TranslatePaymentMethod(rental.paymentMethod)}
					{rental.dealCoupon && <span>: {rental.dealCoupon}</span>}
				</p>
			</div>
			<div className={styles.table_cell}>
				<p>
					<span>
						{ddMMyyyy(new Date(parseDate(rental.startDate)))}
					</span>
					{rental.startDate !== rental.endDate && (
						<span>
							{" "}
							- {ddMMyyyy(new Date(parseDate(rental.endDate)))}
						</span>
					)}
				</p>
				<p className={styles.italic}>
					(
					{daysBetween(
						parseDate(rental.startDate),
						parseDate(rental.endDate)
					) + 1}{" "}
					dage)
				</p>
			</div>
			<div className={styles.table_cell}>
				<form onSubmit={handleUpdateFormSubmit}>
					<textarea
						className={styles.comment_textarea}
						name="comment"
						onChange={(e) => setComment(e.target.value)}
						value={comment}
					></textarea>

					<div className={styles.category_select}>
						<SelectField
							label={null}
							name="category"
							onChangeHandler={(value) =>
								setCategoryId(parseInt(value))
							}
							value={categoryId}
							options={Array.from(
								{ length: props.categories.length },
								(item, i) => i
							).map((i) => {
								return {
									title: props.categories[i].name,
									value: props.categories[i].id,
								};
							})}
						/>
					</div>
					<SubmitButton
						disabled={isSubmitting}
						submitting={isSubmitting}
						disableChevron={true}
						title="Gem"
					/>
					<DeleteRentalButton
						rentalId={rental.id}
					/>
				</form>
			</div>
		</div>
	);
}

export default RentalRow;
