import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Button, ButtonTheme } from "../../../components/common";
import SubmitButton from "../../../components/common/submitButton";
import submitButton from "../../../components/common/submitButton";
import { routes } from "../../../config";
import { logoutAction, selectAuthState } from "../../../redux/authSlice";
import styles from "./adminMain.module.scss";

interface Props extends RouteComponentProps {}

export default function AdminMain(props: Props) {
	const authState = useSelector(selectAuthState);
	const dispatch = useDispatch();

	useEffect(() => {
		!authState.user && props.history.push(routes.logInd);
	}, [authState.user]);

	const handleLogout = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		dispatch(logoutAction());
	};

	return (
		<div className={styles.main}>
			{!!authState.user && (
				<>
					<div className={styles.logoutContainer}>
						<h1 className={styles.h1}>Hej {authState.user!.fullName}</h1>
						<form onSubmit={handleLogout}>
							<SubmitButton
								title="Log ud"
								disableChevron={true}
							/>
						</form>
					</div>

					<div className={styles.linkContainer}>
						<Button
							path={routes.adminRentalProducts}
							text="Produkter"
							theme={ButtonTheme.Dark}
						/>
					</div>

					<div className={styles.linkContainer}>
						<Button
							path={routes.adminRentals}
							text="Udlejninger"
							theme={ButtonTheme.Dark}
						/>
					</div>
				</>
			)}
		</div>
	);
}
