import { useState } from "react";
import { useDispatch } from "react-redux";
import SubmitButton from "../../../../../../components/common/submitButton";
import { deleteRental } from "../../../../../../redux/rentalSlice";
import { rentalService } from "../../../../../../services/rentalService";

interface Props {
	rentalId: number;
}

function DeleteRentalButton(props: Props) {
	const dispatch = useDispatch();
	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleDeleteBtnClick = () => {
		if(!window.confirm("Er du sikker på at du vil slette denne leje?"))
			return;

		setIsSubmitting(true);
		rentalService.rentals
			.deleteRental(props.rentalId)
			.then(() => dispatch(deleteRental({ id: props.rentalId })))
			.catch((error) => console.log(error))
			.finally(() => setIsSubmitting(false));
	};

	return (
		<SubmitButton
			disabled={isSubmitting}
			submitting={isSubmitting}
			title="Slet"
			disableChevron={true}
			onClick={handleDeleteBtnClick}
		/>
	);
}

export default DeleteRentalButton;
