import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { matchPath, NavLink, useLocation } from "react-router-dom";
import { selectMobileMenuIsOpen, toggleMenu } from "../../../../redux/mobileMenuSlice";
import styles from "./mobileMenuAccordion.module.scss";

interface Props {
	title: string;
	items: {title: string, route: string}[];
}

function MobileMenuAccordion(props: Props) {
	const [opened, setOpened] = useState(false);

	const location = useLocation();
	const isAccordionCurrent = props.items.some(item => {
		// * Is the accordion closed and
		// * is the path the current path
		if (opened == false && !!matchPath(location.pathname, item.route)) {
			return true
		} else if(opened == true && !!matchPath(location.pathname, item.route)) {
			return true
		} else if (opened == true && !matchPath(location.pathname, item.route)) {
			return false
		}
	})

	// ! This also exists in MobileMenu.tsx
	// ! and should probably only be in one place.
	const dispatch = useDispatch();
	const mobileMenuIsOpen = useSelector(selectMobileMenuIsOpen);

	const onMenuClick = () => {
		dispatch(toggleMenu({ isOpen: !mobileMenuIsOpen }));

		!mobileMenuIsOpen
			? (document.body.style.overflow = "hidden")
			: (document.body.style.overflow = "visible");

		setOpened(false)
	};

	return (
		<div className={styles.accordion} data-opened={isAccordionCurrent ? !opened : opened}>
			<div className={styles.header} onClick={() => setOpened(!opened)}>
				<span>{props.title}</span>
				<span className={`material-icons ${styles.icon}`}>expand_more</span>
			</div>
			<ul className={styles.content}>
				{props.items.map(item => (
					<li className={styles.li} key={item.title}>
						<NavLink
							onClick={onMenuClick}
							className={styles.link}
							activeClassName={styles.linkActive}
							to={item.route}
						>
							{item.title}
						</NavLink>
					</li>
				))}
			</ul>
		</div>
	);
}

export default MobileMenuAccordion;
