import { RouteComponentProps } from "react-router-dom";
import styles from "./adminForgotPassword.module.scss";
import AdminForgotPasswordForm from "./adminForgotPasswordForm/AdminForgotPasswordForm";

interface Props extends RouteComponentProps {}

export default function AdminForgotPassword(props: Props) {
	return (
		<main className={styles.wrapper}>
			<h1>Glemt adganskode</h1>
			<p>Indtast email for at få tilsendt en email til oprettelse af ny adganskode</p>
			<AdminForgotPasswordForm />
		</main>
	);
}
