import { configureStore, ThunkAction, Action, getDefaultMiddleware } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import contentPagesReducer from './contentPagesSlice';
import mobileMenuReducer from './mobileMenuSlice';
import modalReducer from './modalSlice';
import rentalsReducer from './rentalSlice';
import toastrReducer from './toastrSlice';

export const store = configureStore({
	reducer: {
		auth: authReducer,
		contentPages: contentPagesReducer,
		modal: modalReducer,
		mobileMenu: mobileMenuReducer,
		rentals: rentalsReducer,
		toastr: toastrReducer
	},
	middleware: getDefaultMiddleware({
		serializableCheck: {
			ignoredActions: [
				'rental/calendarSetSelectedStartDate', // because of Date type
				'rental/calendarSetSelectedEndDate'], // because of Date type
		}
	})
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
