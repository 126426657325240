import React, { useEffect } from "react";
import { FormEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectEditableRentalProductState } from "../../../redux/modalSlice";
import { loadRentalProducts } from "../../../redux/rentalSlice";
import { rentalService } from "../../../services/rentalService";
import StatusMessage from "../../common/errorMessage";
import { StatusEnum } from "../../common/errorMessage/StatusMessage";
import ModalHeader from "../../common/modal/modalHeader/ModalHeader";
import SubmitButton from "../../common/submitButton";
import InputField from "../../form/inputField";
import { InputFieldType } from "../../form/inputField/InputField";
import styles from "./editRentalProductModal.module.scss";

interface Props {
	onClose: () => void;
}

function EditRentalProductModal(props: Props) {
	let dispatch = useDispatch();
	let [name, setName] = React.useState("");
	let [namePlural, setNamePlural] = React.useState("");
	let [amountInStock, setAmountInStock] = React.useState(0);
	let [nameValidated, setNameValidated] = React.useState(false);
	let [namePluralValidated, setNamePluralValidated] = React.useState(false);
	let [amountInStockValidated, setAmountInStockValidated] = React.useState(false);
	let [errorMessage, setErrorMessage] = React.useState("");
	let [isSubmitting, setIsSubmitting] = React.useState(false);

	const editableProduct = useSelector(selectEditableRentalProductState);
	let product = editableProduct.product;
	let modalTitle = editableProduct?.type === "create" ? "Opret produkt" : "Opdater produkt"

	useEffect(() => {
		if (!!product && !name) setName(product!.name);
		if (!!product && !namePlural) setNamePlural(product!.namePlural);
		if (!!product && !amountInStock) setAmountInStock(product!.amountInStock);
	}, [editableProduct.product]);

	let handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setIsSubmitting(true);
		setErrorMessage("");

		var promise = editableProduct.type === "create" ?
			rentalService.products.createRentalProduct(name, namePlural, amountInStock) :
			rentalService.products.updateRentalProduct(product!.id, name, namePlural, amountInStock);

		promise
			.then(() => {
				dispatch(loadRentalProducts());
				props.onClose();
			})
			.catch((error) => {
				setErrorMessage("Noget gik galt i forsøget på at oprette/opdatere produktet. Prøv evt igen.");
				setIsSubmitting(false);
			})
	};

	let isValidated = () => nameValidated && namePluralValidated && amountInStockValidated;

	return (
		<>
			<ModalHeader title={modalTitle} />
			<form onSubmit={handleFormSubmit}>
				<div className={styles.input}>
					<InputField
						label="Navn"
						name="name"
						placeholder="Helikopter..."
						value={name}
						minLength={1}
						type={InputFieldType.text}
						onChangeHandler={(event) => setName(event.target.value)}
						setIsValid={(isValid) => setNameValidated(isValid)}
					/>
				</div>

				<div className={styles.input}>
					<InputField
						label="Navn (flertal)"
						name="namePlural"
						placeholder="Helikoptere..."
						value={namePlural}
						minLength={1}
						type={InputFieldType.text}
						onChangeHandler={(event) => setNamePlural(event.target.value)}
						setIsValid={(isValid) => setNamePluralValidated(isValid)}
					/>
				</div>

				<div className={styles.input}>
					<InputField
						label="På lager"
						name="amountInStock"
						placeholder="5..."
						value={amountInStock}
						minValue={1}
						type={InputFieldType.number}
						onChangeHandler={(event) => setAmountInStock(parseInt(event.target.value))}
						setIsValid={(isValid) => setAmountInStockValidated(isValid)}
					/>
				</div>
				<div className={`${styles.content_end} ${styles.button}`}>
					<SubmitButton
						disabled={!isValidated() || isSubmitting}
						submitting={isSubmitting}
						title={modalTitle}
					/>
					{!!errorMessage &&
						<StatusMessage text={errorMessage} status={StatusEnum.error} ></StatusMessage>}
				</div>
			</form>
		</>
	);
}

export default EditRentalProductModal;
