import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Spinner } from "../../../components/common";
import { SpinnerTheme } from "../../../components/common/spinner/Spinner";
import { routes } from "../../../config";
import { selectIsAuthorized } from "../../../redux/authSlice";
import { LoadingStatusEnum } from "../../../redux/loadingStatusEnum";
import { loadRentalsDetailed, loadRentalStatistics, selectRentalSDetailed, selectRentalStatistics, useRentalCategories } from "../../../redux/rentalSlice";
import { dateMs, parseDate } from "../../../utility";
import styles from "./adminRentals.module.scss";
import RentalRow from "./components/rentalRow";
import RentalTableHeader from "./components/rentalTableHealder";

interface Props extends RouteComponentProps {}

export default function AdminRentals(props: Props) {
	const isAuthorized = useSelector(selectIsAuthorized);
	const rentalsState = useSelector(selectRentalSDetailed);
	const rentalStatisticsState = useSelector(selectRentalStatistics);
	const upcomingRentals = rentalsState.rentals
		.filter(x => dateMs(parseDate(x.endDate)) >= dateMs(new Date()))
		.sort((a, b) => dateMs(parseDate(a.startDate)) > dateMs(parseDate(b.startDate)) ? 1 : -1);
	const pastRentals = rentalsState.rentals
		.filter(x => dateMs(parseDate(x.endDate)) < dateMs(new Date()))
		.sort((a, b) => dateMs(parseDate(a.endDate)) > dateMs(parseDate(b.endDate)) ? -1 : 1);
	const rentalCategoriesState = useRentalCategories();
	const dispatch = useDispatch();

	useEffect(() => {
		!isAuthorized && props.history.push(routes.logInd);
	}, [isAuthorized]);

	useEffect(() => {
		dispatch(loadRentalsDetailed());
		dispatch(loadRentalStatistics());
	}, []);

	return (
		<div className={styles.main}>
			<h1 className={styles.h1}>Udlejninger</h1>
			<section>
				<h3>Udlejningsstatistikker</h3>
				{rentalStatisticsState.loadingStatus !== LoadingStatusEnum.Loaded ? (
					<Spinner theme={SpinnerTheme.Dark} />
				) : (
					<>
						{rentalStatisticsState.items.map(x => (
							<div><span><strong>{x.productNamePlural}:</strong> {x.count}</span></div>
						))}
					</>
				)}
			</section>
			{rentalsState.loadingStatus !== LoadingStatusEnum.Loaded ? (
				<Spinner theme={SpinnerTheme.Dark} />
			) : (
				<>
					<section>
						<h3>Kommende udlejninger</h3>
						<div className={styles.rentals_table}>
							<RentalTableHeader />
							{upcomingRentals.map((rental) => (
								<RentalRow rental={rental} categories={rentalCategoriesState.categories} key={rental.id}/>
								))}
						</div>
					</section>

					<section>
						<h3>Tidligere udlejninger</h3>
						<div className={styles.rentals_table}>
							<RentalTableHeader />
							{pastRentals.map((rental) => (
								<RentalRow rental={rental} categories={rentalCategoriesState.categories} key={rental.id}/>
								))}
						</div>
					</section>
				</>
			)}
		</div>
	);
}