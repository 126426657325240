import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Spinner } from "../../components/common";
import { SpinnerTheme } from "../../components/common/spinner/Spinner";
import { RentalApiResponse } from "../../services/apiModels/response/RentalApiResponse";
import { rentalService } from "../../services/rentalService";
import { ddMMyyyy, parseDate } from "../../utility/dateUtils";
import styles from "./rentalCancellation.module.scss";
import RentalCancellationForm from "./rentalCancellationForm";

interface Params {
	id: string;
}
function RentalCancellation() {
	let { id } = useParams<Params>();
	console.log(id);
	const [rental, setRental] = useState<RentalApiResponse | null>(null);
	let [errorMessage, setErrorMessage] = useState("");
	let [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		rentalService.rentals
			.getRental(parseInt(id))
			.then((response) => setRental(response))
			.catch((error) =>
				setErrorMessage(
					`Booking med referencenummer ${id} kunne ikke findes. Måske er den allerede blevet aflyst?`
				)
			)
			.finally(() => setLoading(false));
	}, []);

	return (
		<main className={styles.wrapper}>
			<section>
				<h1>Aflys booking</h1>
				{!!loading && <Spinner theme={SpinnerTheme.Dark} />}
				{!!errorMessage && <p>{errorMessage}</p>}
				{rental && (
					<div>
						<p><strong>Referencenummer: </strong>{rental.id}</p>
						<p>
							<strong>Periode: </strong>
							<span>{ddMMyyyy(new Date(parseDate(rental.startDate)))}
							</span>
							{rental.startDate !== rental.endDate && (
								<span>{" "}-{" "}{ddMMyyyy(new Date(parseDate(rental.endDate)))}</span>
							)}
						</p>
						<p><strong>Produkter:</strong></p>
						{rental.items.map((item) => (
							<ul key={item.productId}>
								<li>{item.productName}: {item.count}</li>
							</ul>
						))}
					</div>
				)}
			</section>
			{rental && (
				<section className={styles.form_section}>
					<p>Det er gratis at aflyse en booking, men gør det venligst senest en dag før startdato.</p>
					<RentalCancellationForm rentalId={parseInt(id)} />
				</section>
			)}
		</main>
	);
}

export default RentalCancellation;
