import React, { FormEvent, useEffect } from "react";
import styles from "./updatePasswordForm.module.scss";
import InputField from "../../../../components/form/inputField";
import { InputFieldType } from "../../../../components/form/inputField/InputField";
import SubmitButton from "../../../../components/common/submitButton";
import { accountService } from "../../../../services/accountService";
import StatusMessage from "../../../../components/common/errorMessage";
import { StatusEnum } from "../../../../components/common/errorMessage/StatusMessage";

interface Props{
	resetPasswordToken: string;
}

function UpdatePasswordForm(props: Props) {
	let [password, setPassword] = React.useState("");
	let [passwordValidated, setPasswordValidated] = React.useState(false);
	let [isTempLockedOut, setIsTempLockedOut] = React.useState(false); // used to avoid spamming send
	let [isSubmitting, setIsSubmitting] = React.useState(false);
	let [errorMessage, setErrorMessage] = React.useState("");
	let [successMessage, setSuccessMessage] = React.useState("");

	let handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setIsSubmitting(true);
		setErrorMessage("");
		setSuccessMessage("");

		accountService
			.updatePassword(props.resetPasswordToken, password)
			.then(() => {
				setSuccessMessage("Din adgangskode blev opdateret!");
				setPassword("");
			})
			.catch((error) => setErrorMessage("Det lykkedes ikke at opdatere adgangskoden. Måske er linket udløbet. Prøv at starte processen forfra."))
			.finally(() => {
				setIsTempLockedOut(true);
				setIsSubmitting(false);
			});
	};

	useEffect(() => {
		if (isTempLockedOut === false) return;

		let timeout = setTimeout(() => {
			setIsTempLockedOut(false);
		}, 3000);

		return () => clearTimeout(timeout);
	}, [isTempLockedOut]);

	let isValidated = () => passwordValidated && !isTempLockedOut;

	return (
		<div className={styles.main}>
			<form onSubmit={handleFormSubmit}>
				<div>
					<InputField
						label="Adgangskode"
						name="password"
						placeholder="asb#¤124!$kjh..."
						value={password}
						type={InputFieldType.password}
						onChangeHandler={(event) => setPassword(event.target.value)}
						setIsValid={(isValid) => setPasswordValidated(isValid)}
					/>
				</div>
				<div className={styles.button}>
					<SubmitButton
						disabled={!isValidated() || isSubmitting}
						submitting={isSubmitting}
						title="Opdater adgangskode"
					/>
				</div>
				<div>{!!successMessage && ( <StatusMessage text={successMessage} status={StatusEnum.successDark} ></StatusMessage> )}</div>
				<div>{!!errorMessage && ( <StatusMessage text={errorMessage} status={StatusEnum.error} ></StatusMessage> )}
				</div>
			</form>
		</div>
	);
}

export default UpdatePasswordForm;