import styles from "./contentTile.module.scss";

interface Props {
	heading: string;
	text: string;
}

function ContentTile(props: Props) {
	return (
		<div className={styles.contentTile}>
			<h2 className={styles.heading}>{props.heading}</h2>
			<p dangerouslySetInnerHTML={{__html: props.text}}></p>
		</div>
	);
}

export default ContentTile;
