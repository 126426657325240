import { httpService } from "./httpService";
import { apiEndpoints } from "../config/apiConfig";
import { RentalProductApiResponse } from "./apiModels/response/RentalProductApiResponse";
import { ApiException } from "./exceptions";
import { RentalApiResponse } from "./apiModels/response/RentalApiResponse";
import { addYears, yyyyMMdd } from "../utility/dateUtils";
import { CreateRentalApiRequest } from "./apiModels/request/CreateRentalApiRequest";
import { RentalPaymentMethodEnum } from "./apiModels/enums/RentalPaymentMethodEnum";
import { RentalDetailedApiResponse } from "./apiModels/response/RentalDetailedApiResponse";
import { RentalCategoryApiResponse } from "./apiModels/response/RentalCategoryApiResponse";
import { UpdateRentalApiRequest } from "./apiModels/request/UpdateRentalApiRequest";
import { RentalStatisticsApiResponse } from "./apiModels/response/RentalStatisticsApiResponse";

export const rentalService = {
	rentals: {
		getRental: async (id: number): Promise<RentalApiResponse> => {
			return await httpService.get<RentalApiResponse>(
				apiEndpoints.rentals.getRental.replace("{id}", id.toString()));
		},

		getRentals: async (): Promise<RentalApiResponse[]> => {
			return await httpService.get<RentalApiResponse[]>(
				`${apiEndpoints.rentals.getRentals}?startDate=${yyyyMMdd(new Date())}&endDate=${yyyyMMdd(addYears(new Date(), 5))}`);
		},

		getRentalsDetailed: async (): Promise<RentalDetailedApiResponse[]> => {
			return await httpService.get<RentalDetailedApiResponse[]>(
				`${apiEndpoints.rentals.getRentalsDetailed}?startDate[gte]=${yyyyMMdd(addYears(new Date(), -4))}&endDate[lte]=${yyyyMMdd(addYears(new Date(), 5))}`);
		},

		getRentalStatistics: async (): Promise<RentalStatisticsApiResponse> => {
			return await httpService.get<RentalStatisticsApiResponse>(
				`${apiEndpoints.rentals.getRentalStatistics}`);
		},

		createRental: async (request: CreateRentalApiRequest): Promise<RentalApiResponse> => {
			return await httpService.post<RentalApiResponse>(
				apiEndpoints.rentals.createRental,
				{
					...request,
					paymentMethod: RentalPaymentMethodEnum[request.paymentMethod]}
			);
		},

		updateRental: async (id: number, request: UpdateRentalApiRequest): Promise<RentalApiResponse> => {
			return await httpService.put<RentalApiResponse>(
				apiEndpoints.rentals.updateRental.replace("{id}", id.toString()),
				request
			);
		},

		deleteRental: async (id: number, phone?: string): Promise<any> => {
			return await httpService.delete(
				apiEndpoints.rentals.deleteRental
					.replace("{id}", id.toString())
					.replace("{phone}", phone ?? ""));
		},

		finishRental: async (id: number, phone: string): Promise<any> => {
			return await httpService.put(
				apiEndpoints.rentals.finishRental
					.replace("{id}", id.toString())
					.replace("{phone}", phone), {});
		},

		createRentalProduct: async (name: string, amountInStock: number): Promise<RentalProductApiResponse> => {
			return await httpService.post<RentalProductApiResponse>(
				apiEndpoints.rentalProducts.createRentalProduct,
				{
					name: name,
					amountInStock: amountInStock
				}
			);
		},

		updateRentalProduct: async (productId: number, name: string, amountInStock: number): Promise<RentalProductApiResponse> => {
			return await httpService.put<RentalProductApiResponse>(
				apiEndpoints.rentalProducts.updateRentalProduct
					.replace("{id}", productId.toString()),
				{
					name: name,
					amountInStock: amountInStock
				}
			);
		},

		deleteRentalProduct: async (productId: number): Promise<any> => {
			return await httpService.delete<RentalProductApiResponse>(
				apiEndpoints.rentalProducts.deleteRentalProduct
					.replace("{id}", productId.toString())
			);
		},
	},
	categories: {
		getRentalCategories: async (): Promise<RentalCategoryApiResponse[]> => {
			return await httpService.get<RentalCategoryApiResponse[]>(apiEndpoints.rentalCategories.getRentalCategories);
		},
	},
	products: {
		getRentalProducts: async (): Promise<RentalProductApiResponse[]> => {
			return await httpService.get<RentalProductApiResponse[]>(apiEndpoints.rentalProducts.getRentalProducts);
		},

		createRentalProduct: async (name: string, namePlural: string, amountInStock: number): Promise<RentalProductApiResponse> => {
			return await httpService.post<RentalProductApiResponse>(
				apiEndpoints.rentalProducts.createRentalProduct,
				{
					name: name,
					namePlural: namePlural,
					amountInStock: amountInStock
				}
			);
		},

		updateRentalProduct: async (productId: number, name: string, namePlural: string, amountInStock: number): Promise<RentalProductApiResponse> => {
			return await httpService.put<RentalProductApiResponse>(
				apiEndpoints.rentalProducts.updateRentalProduct
					.replace("{id}", productId.toString()),
				{
					name: name,
					namePlural: namePlural,
					amountInStock: amountInStock
				}
			);
		},

		deleteRentalProduct: async (productId: number): Promise<any> => {
			return await httpService.delete<RentalProductApiResponse>(
				apiEndpoints.rentalProducts.deleteRentalProduct
					.replace("{id}", productId.toString())
			);
		},
	},

	productPrices: {
		createRentalProductPrice: async (productId: number, unitPrice: number, quantity: number): Promise<RentalProductApiResponse> => {
			return await httpService.post<RentalProductApiResponse>(
				apiEndpoints.rentalProductPrices.createRentalProductPrice
					.replace("{id}", productId.toString()),
				{
					unitPrice: unitPrice,
					quantity: quantity
				}
			);
		},

		updateRentalProductPrice: async (productId: number, priceId: number, unitPrice: number, quantity: number): Promise<RentalProductApiResponse> => {
			var promise = httpService.put<RentalProductApiResponse>(
				apiEndpoints.rentalProductPrices.updateRentalProductPrice
					.replace("{id}", productId.toString())
					.replace("{priceId}", priceId.toString()),
				{
					unitPrice: unitPrice,
					quantity: quantity
				}
			);

			promise.catch(error => {
				if (error instanceof ApiException === true) {
					var friendlyText = (error as ApiException).getFriendlyErrorTxt();
					if(!!friendlyText)
						window.alert(friendlyText);
				}
			});

			return await promise;
		},

		deleteRentalProduct: async (productId: number, priceId: number): Promise<any> => {
			return await httpService.delete<RentalProductApiResponse>(
				apiEndpoints.rentalProductPrices.deleteRentalProductPrice
					.replace("{id}", productId.toString())
					.replace("{priceId}", priceId.toString())
			);
		},
	}
}
