export class ApiException extends Error {
	public apiErrorCode!: number;
	public apiErrorMessage!: string;

	constructor(apiErrorCode: number, apiErrorMessage: string) {
		super(undefined);
		this.apiErrorCode = apiErrorCode;
		this.apiErrorMessage = apiErrorMessage;
	}

	public getFriendlyErrorTxt = () => {
		const map: { [code: number]: string } = {
			110001: "Email-adressen findes ikke.",
			110002: "Der er ikke oprettet en adgangskode endnu for denne konto. Få evt tilsendt en ny email.",
			110003: "Adgangskoden er forkert",
			130007: "Udlejningen kan ikke færdiggøres før startdatoen.",
			130102: "Der skal altid være en pris med 1 antal. Denne kan ikke slettes eller modificeres.",
			130152: "Produktet er i brug af eksisterende udlejninger og kan derfor ikke slettes.",
		}

		var friendlyTxt = map[this.apiErrorCode];
		if (!friendlyTxt)
			console.log(`ERROR: no friendly text was found for code: ${this.apiErrorCode}.`);

		return friendlyTxt;
	};
}