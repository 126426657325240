import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import LoginForm from "../../../components/form/loginForm";
import { routes } from "../../../config";
import { selectAuthState } from "../../../redux/authSlice";
import styles from "./adminLogin.module.scss";

interface Props extends RouteComponentProps {}

export default function AdminLogin(props: Props) {
	const authState = useSelector(selectAuthState);

	useEffect(() => {
		if (!!authState.user) {
			props.history.push(routes.adminMain);
		}
	}, [authState.user]);

	return (
		<main className={styles.wrapper}>
			<h1>Log ind</h1>
			<LoginForm></LoginForm>
		</main>
	);
}
