import { Link } from "react-router-dom";
import styles1 from "./dark.module.scss";
import styles2 from "./light.module.scss";
import styles3 from "./transparent.module.scss";
import styles4 from "./gradient.module.scss";

enum ButtonTheme {
	Dark = "Dark",
	Light = "Light",
	Transparent = "Transparent",
	Gradient = "Gradient"
}

const styles = {
	Dark: styles1,
	Light: styles2,
	Transparent: styles3,
	Gradient: styles4
}

interface Props {
	theme: ButtonTheme,
	path: string,
	text: string,
	customCSS?: string
}

function Button(props: Props) {
	return (
		<Link className={`${styles[props.theme].button} ${props.customCSS}`} to={props.path}>
			<span className={styles[props.theme].content}>
				{props.text}
				<span className={`material-icons`}>chevron_right</span>
			</span>
		</Link>
	);
}

export {
	Button,
	ButtonTheme
}
