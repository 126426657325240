import BlockQuote from "../../components/common/blockQuote";
import ContactForm from "../../components/form/contactForm";
import styles from "./contact.module.scss";

function Contact() {
	return (
		<main>
			<section className={styles.top}>
				<div className={`${styles.wrapper} ${styles.top__content}`}>
					<h1>Kontakt</h1>
					<p><strong>Ken Leschley Andersen</strong></p>
					<div>
						Tlf: <a href="tel:23244171">23 24 41 71</a>
					</div>
					<div>
						Mail: <a href="mailto:mail@aarhusvandsportscenter.dk">mail@aarhusvandsportscenter.dk</a>
					</div>
				</div>
			</section>

			<section className={`${styles.wrapper} ${styles.contact}`}>
				<div className={styles.contact__content}>
					<BlockQuote text="Du kan også sende os en besked."></BlockQuote>
				</div>

				<ContactForm></ContactForm>
			</section>
		</main>
	);
}

export default Contact;
