import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { accountService } from '../services/accountService';
import { ApiException } from '../services/exceptions';
import { LoadingStatusEnum } from './loadingStatusEnum';
import { AppThunk, RootState } from './store';

export const localStorageAuthUserKey = "authUser";
let user = localStorage.getItem(localStorageAuthUserKey) ? JSON.parse(localStorage.getItem(localStorageAuthUserKey) as string) as AuthUserState : undefined;

export interface AuthUserState {
	email: string,
	fullName: string,
	token: string,
}
interface AuthState {
	user: AuthUserState | null,
	loadingStatus: LoadingStatusEnum,
	errorMessage: string | null
}

const initialState: AuthState = {
	user: !!user ? {
		fullName: user.fullName,
		email: user.email,
		token: user.token
	} : null,
	loadingStatus: LoadingStatusEnum.NotLoaded,
	errorMessage: null
};

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		loginRequest: state => {
			state.loadingStatus =  LoadingStatusEnum.Loading;
			state.errorMessage = null;
		},
		loginSuccess: (state, action: PayloadAction<AuthUserState>) => {
			state.loadingStatus =  LoadingStatusEnum.Loaded;
			state.user = action.payload;
		},
		loginError: (state, action: PayloadAction<{ errorMsg: string }>) => {
			state.loadingStatus =  LoadingStatusEnum.NotLoaded;
			state.errorMessage = action.payload.errorMsg
		},
		logout: state => {
			state.user = null;
		}
	},
});

export const { loginRequest, loginSuccess, loginError, logout } = authSlice.actions;

// Thunks
export const loginAction = (email: string, password: string): AppThunk => async dispatch => {
	dispatch(loginRequest());

	try {

		let response = await accountService.login(email, password);
		let payload = { email: response.email, fullName: response.fullName, token: response.jwt } as AuthUserState;
		dispatch(loginSuccess(payload));
		localStorage.setItem(localStorageAuthUserKey, JSON.stringify(payload));
	}
	catch (error) {
		var errorMsg = "En fejl skete i forsøget på at logge ind";
		if (error instanceof ApiException === true) {
			var friendlyText = (error as ApiException).getFriendlyErrorTxt();
			!!friendlyText && (errorMsg = friendlyText);
		}

		dispatch(loginError({ errorMsg: errorMsg }));
	}
};

export const logoutAction = (): AppThunk => dispatch => {
	localStorage.removeItem(localStorageAuthUserKey);
	dispatch(logout());
}


// Selectors
export const selectAuthState = (state: RootState) => state.auth;
export const selectIsAuthorized = (state: RootState) => !!state.auth.user;

export default authSlice.reducer;