import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { ContentPageApiResponse } from '../services/apiModels/response/ContentPageApiResponse';
import { contentPageService } from '../services/contentPageService';
import { LoadingStatusEnum } from './loadingStatusEnum';
import { AppThunk, RootState } from './store';

interface ContentPagesState {
	contentPages: { [key: string]: ContentPageApiResponse },
	loadingStatus: LoadingStatusEnum,
	errorMessage: string | null
}

const initialState: ContentPagesState = {
	contentPages: {},
	loadingStatus: LoadingStatusEnum.NotLoaded,
	errorMessage: null
};

export const contentPagesSlice = createSlice({
	name: 'contentPages',
	initialState,
	reducers: {
		getContentPageRequest: state => {
			state.loadingStatus =  LoadingStatusEnum.Loading;
			state.errorMessage = null;
		},
		getContentPageSuccess: (state, action: PayloadAction<{ pageKey: string, contentPage: ContentPageApiResponse }>) => {
			state.loadingStatus = LoadingStatusEnum.Loaded;
			state.contentPages[action.payload.pageKey] = action.payload.contentPage;
		},
		getContentPageError: (state, action: PayloadAction<{ errorMsg: string }>) => {
			state.loadingStatus = LoadingStatusEnum.NotLoaded;
			state.errorMessage = action.payload.errorMsg
		}
	},
});

export const { getContentPageError: getContentPagesError, getContentPageRequest: getContentPagesRequest, getContentPageSuccess: getContentPagesSuccess } = contentPagesSlice.actions;

// Thunks
export const loadContentPageAction = (pageKey: string): AppThunk => async dispatch => {
	dispatch(getContentPagesRequest());

	try {
		let response = await contentPageService.getContentPage(pageKey);
		let payload = { pageKey, contentPage: response };
		dispatch(getContentPagesSuccess(payload));
	}
	catch (error) {
		var errorMsg = "En fejl skete i forsøget på at indlæse sidens indhold";
		dispatch(getContentPagesError({ errorMsg }));
	}
};


// Selectors
export const selectContentPage = (pageKey: string) => (state: RootState) => {
	return {
		loading: state.contentPages.loadingStatus,
		contentPage: state.contentPages.contentPages[pageKey]
	}
};

export const selectContentPageSection = (pageKey: string, sectionKey: string) => (state: RootState) => {
	var cp = state.contentPages.contentPages[pageKey];
	var section = cp?.sections.find(x => x.key === sectionKey);
	return section
};

// custom hooks

export function useContentPage(pageKey: string){
	var cp = useSelector(selectContentPage(pageKey));
	var dispatch = useDispatch();

	if(!cp.contentPage && cp.loading !== LoadingStatusEnum.Loading)
		dispatch(loadContentPageAction(pageKey));

	return cp;
}

export default contentPagesSlice.reducer;