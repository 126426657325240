export const DealSites = [
	{
		displayName: "Spotdeal",
		value: "spotdeal",
		codeWord: "værdibevis-ID og kode",
	},
	{
		displayName: "Sweetdeal",
		value: "sweetdeal",
		codeWord: "værdibeviskode",
	},
	{
		displayName: "Downtown",
		value: "downtown",
		codeWord: "referencenummer",
	},
	{
		displayName: "Deal.Dk",
		value: "dealdk",
		codeWord: "værdibevis-ID og kode",
	},
	{
		displayName: "Go Dream",
		value: "go-dream",
		codeWord: "kode",
	},
	{
		displayName: "Smartbox",
		value: "smartbox",
		codeWord: "kode",
	},
	{
		displayName: "My Price",
		value: "my-price",
		codeWord: "kode",
	},
	{
		displayName: "Morebox",
		value: "morebox",
		codeWord: "kode",
	},
	{
		displayName: "Andet",
		value: "other",
		codeWord: "kode og deal-selskab",
	},
];