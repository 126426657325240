import { EditableRentalProductPrice } from "../../../../../components/common/editableStuff";
import { RentalProductPriceApiResponse } from "../../../../../services/apiModels/response/RentalProductPriceApiResponse";
import styles from "./rentalProductPrice.module.scss";

interface Props {
	productId: number,
	price: RentalProductPriceApiResponse
};

function RentalProductPrice(props: Props) {
	return (
		<div className={styles.main}>
			<EditableRentalProductPrice
				productId={props.productId}
				priceId={props.price.id}
			>
				<span className={styles.lhs}>
					<span className={styles.heading}>Antal:</span>
					<span className={styles.value}>{props.price.quantity}</span>
				</span>
				{" - "}
				<span className={styles.rhs}>
					<span className={styles.heading}>Stkpris:</span>
					<span className={styles.value}>{props.price.unitPrice} DKK</span>
				</span>
			</EditableRentalProductPrice>
		</div>
	);
}

export default RentalProductPrice;
