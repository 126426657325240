import styles from "./blockQuote.module.scss";

interface Props {
	text: string;
}

function BlockQuote(props: Props) {
	return (
		<blockquote className={styles.container}>
			<p className={styles.quote}>{props.text}</p>
		</blockquote>
	);
}

export default BlockQuote;
