import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from './store';

export interface ToastrItem {
	id: string;
	title: string;
	message: string;
	type: "success"; // | "warning" | "error";
}

interface ToastrState {
	items: ToastrItem[];
}

const initialState: ToastrState = {
	items: []
};

// Selectors
export const selectToastrItems = (state: RootState) => state.toastr.items;

// Actions + reducers
export const toastrSlice = createSlice({
	name: 'toastrSlice',
	initialState,
	reducers: {
		showToastr: (state, action: PayloadAction<ToastrItem>) => {
			state.items.push(action.payload);
		},
		removeToastr: (state, action: PayloadAction<{id: string}>) => {
			var list = state.items;
			const index = list.findIndex(e => e.id === action.payload.id);
			list.splice(index, 1);
			state.items = list;
		}
	},
});


// Thunks
export const showToastrMessage = (title: string, message: string): AppThunk => async dispatch => {
	var toastr: ToastrItem = {
		id: Math.random().toString().substring(2, 15), // lmao
		title: title,
		message: message,
		type: "success"
	};
	dispatch(toastrSlice.actions.showToastr(toastr));

	setTimeout(() => {
		dispatch(toastrSlice.actions.removeToastr({id: toastr.id}))
	}, 10000);
};

export default toastrSlice.reducer;