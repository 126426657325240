import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { RentalStateSelectedProduct } from "../redux/rentalSlice";

export interface ModalBodyContentPage {
	pageKey: string;
	pageSectionKey: string;
}

export interface ModalBodyCreateRental {
	// empty for now. all the necessary data are saved in other states
}

export interface ModalBodyRentalProduct {
	productId?: number; // only required if type is update
	type: "create" | "update"
}

export interface ModalBodyRentalProductPrice {
	productId: number;
	priceId?: number; // only required if type is update
	type: "create" | "update"
}

interface ModalState {
	show: boolean;
	body: { // only one of these will have a value
		contentPage?: ModalBodyContentPage;
		rentalProduct?: ModalBodyRentalProduct;
		createRental?: ModalBodyCreateRental;
		rentalProductPrice?: ModalBodyRentalProductPrice;
	};
}

const initialState: ModalState = {
	show: false,
	body: {}
};

// Actions + reducers
export const modalSlice = createSlice({
	name: 'modalSlice',
	initialState,
	reducers: {
		openModal_createRental: (state) => {
			state.show = true;
			state.body = {
				createRental: {}
			}
		},
		openModal_contentPage: (state, action: PayloadAction<{ body: ModalBodyContentPage }>) => {
			state.show = true;
			state.body = {
				contentPage: action.payload.body
			}
		},
		openModal_rentalProduct: (state, action: PayloadAction<{ body: ModalBodyRentalProduct }>) => {
			state.show = true;
			state.body = {
				rentalProduct: action.payload.body
			}
		},
		openModal_rentalProductPrice: (state, action: PayloadAction<{ body: ModalBodyRentalProductPrice }>) => {
			state.show = true;
			state.body = {
				rentalProductPrice: action.payload.body
			}
		},
		closeModal: (state) => {
			state.show = false;
			state.body = {};
		}
	},
});

export const {
	openModal_contentPage,
	openModal_rentalProduct,
	openModal_rentalProductPrice,
	openModal_createRental,
	closeModal } = modalSlice.actions;

// Selectors
export const selectModal = (state: RootState) => {
	return state.modal;
};

export const selectModalShow = (state: RootState) => {
	return state.modal.show;
};

export const selectEditableContentPage = (state: RootState) => {
	var pageKey = state.modal.body.contentPage?.pageKey;
	var pageSectionKey = state.modal.body.contentPage?.pageSectionKey;

	if (!state.modal.show || !pageKey)
		return null;

	var cp = state.contentPages.contentPages[pageKey]
	var section = state.contentPages.contentPages[pageKey].sections.find(x => x.key === pageSectionKey)!;
	return { cp: cp, section: section };
};

export const selectEditableRentalProductState = (state: RootState) => {
	var productId = state.modal.body.rentalProduct?.productId

	// will be null if modal is not opened or not using content page body
	var product = !!productId ? state.rentals.productsData.products.find(x => x.id === productId) : null;
	return {
		...state.modal.body.rentalProduct,
		product
	}
};

export const selectEditableRentalProductPriceState = (state: RootState) => {
	var productId = state.modal.body.rentalProductPrice?.productId
	var priceId = state.modal.body.rentalProductPrice?.priceId

	// will be null if modal is not opened or not using content page body
	var price = !!productId ? state.rentals.productsData.products
		.find(x => x.id === productId)
		?.prices.find(x => x.id === priceId) : null;

	return {
		...state.modal.body.rentalProductPrice,
		price
	}
};

export default modalSlice.reducer;