import React, { useEffect } from "react";
import { FormEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectEditableRentalProductPriceState } from "../../../redux/modalSlice";
import { loadRentalProducts } from "../../../redux/rentalSlice";
import { rentalService } from "../../../services/rentalService";
import StatusMessage from "../../common/errorMessage";
import { StatusEnum } from "../../common/errorMessage/StatusMessage";
import ModalHeader from "../../common/modal/modalHeader/ModalHeader";
import SubmitButton from "../../common/submitButton";
import InputField from "../../form/inputField";
import { InputFieldType } from "../../form/inputField/InputField";
import styles from "./editRentalProductPriceModal.module.scss";

interface Props {
	onClose: () => void;
}

function EditRentalProductModal(props: Props) {
	let dispatch = useDispatch();
	let [quantiy, setQuantity] = React.useState(0);
	let [unitPrice, setUnitPrice] = React.useState(0);
	let [quantityValidated, setQuantityValidated] = React.useState(false);
	let [unitPriceValidated, setUnitPriceValidated] = React.useState(false);
	let [errorMessage, setErrorMessage] = React.useState("");
	let [isSubmitting, setIsSubmitting] = React.useState(false);

	const editableProductPrice = useSelector(selectEditableRentalProductPriceState);
	let price = editableProductPrice.price;
	let modalTitle = editableProductPrice?.type === "create" ? "Opret pris" : "Opdater pris"

	useEffect(() => {
		if (!!price && !quantiy) setQuantity(price!.quantity);
		if (!!price && !unitPrice) setUnitPrice(price!.unitPrice);
	}, [price]);

	let handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setIsSubmitting(true);
		setErrorMessage("");

		var promise = editableProductPrice.type === "create" ?
			rentalService.productPrices.createRentalProductPrice(editableProductPrice.productId!, unitPrice, quantiy) :
			rentalService.productPrices.updateRentalProductPrice(editableProductPrice.productId!, editableProductPrice.priceId!, unitPrice, quantiy);

		promise
			.then(() => {
				dispatch(loadRentalProducts());
				props.onClose();
			})
			.catch((error) => {
				setErrorMessage("Noget gik galt i forsøget på at oprette/opdatere produktprisen. Prøv evt igen.");
				setIsSubmitting(false);
			})
	};

	let isValidated = () => quantityValidated && unitPriceValidated;

	return (
		<>
			<ModalHeader title={modalTitle} />
			<form onSubmit={handleFormSubmit}>
				<div className={styles.input}>
					<InputField
						label="Antal"
						name="quantity"
						placeholder="5..."
						value={quantiy}
						minValue={1}
						type={InputFieldType.number}
						onChangeHandler={(event) => setQuantity(parseInt(event.target.value))}
						setIsValid={(isValid) => setQuantityValidated(isValid)}
					/>
				</div>

				<div className={styles.input}>
					<InputField
						label="Stk. pris"
						name="unitPrice"
						placeholder="100..."
						value={unitPrice}
						minValue={1}
						type={InputFieldType.number}
						onChangeHandler={(event) => setUnitPrice(parseInt(event.target.value))}
						setIsValid={(isValid) => setUnitPriceValidated(isValid)}
					/>
				</div>
				<div className={`${styles.content_end} ${styles.button}`}>
					<SubmitButton
						disabled={!isValidated() || isSubmitting}
						submitting={isSubmitting}
						title={modalTitle}
					/>
					{!!errorMessage &&
						<StatusMessage text={errorMessage} status={StatusEnum.error} ></StatusMessage>}
				</div>
			</form>
		</>
	);
}

export default EditRentalProductModal;
