import { httpService } from "./httpService";
import { apiEndpoints } from "../config/apiConfig";
import { ContentPageApiResponse } from "./apiModels/response/ContentPageApiResponse";

export const contentPageService = {
	getContentPage: async (pageKey: string): Promise<ContentPageApiResponse> => {
		return await httpService.get<ContentPageApiResponse>(
			apiEndpoints.contentPages.getContentPage.replace("{key}", pageKey)
		);
	},

	putContentPageSection: async (pageKey: string, sectionKey: string, title: string, content: string): Promise<ContentPageApiResponse> => {
		return await httpService.put<ContentPageApiResponse>(
			apiEndpoints.contentPages.updateContentPageSection
				.replace("{key}", pageKey)
				.replace("{sectionKey}", sectionKey),
			{
				title: title,
				content: content
			}
		);
	}
}
