import styles from "./modalHeader.module.scss"

interface Props{
	title: string
}

function ModalHeader(props: Props) {
	return <div><h2>{props.title}</h2></div>;
}

export default ModalHeader;
