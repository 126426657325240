import { useSelector } from "react-redux";
import { selectToastrItems } from "../../redux/toastrSlice";
import styles from "./toastr.module.scss";

function Toastr() {
	const toastrItems = useSelector(selectToastrItems);

	return (
		<div className={styles.notification_container}>
                {
                    toastrItems.map((toast, i) =>
                        <div
                            key={i}
                            className={styles.notification}
                        >
							<p className={styles.notification_title}>{toast.title}</p>
							<p className={styles.notification_message}>{toast.message}</p>
                        </div>
                    )
                }
            </div>
	);
}

export default Toastr;
