import React from "react";
import { FormEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { calendarSetSelectedEndDate, calendarSetSelectedStartDate, loadRentalProducts, loadRentals, selectRentalCalendar, selectRentalSelectedProducts } from "../../../redux/rentalSlice";
import { CreateRentalApiRequest } from "../../../services/apiModels/request/CreateRentalApiRequest";
import { RentalPaymentMethodEnum, TranslatePaymentMethod } from "../../../services/apiModels/enums/RentalPaymentMethodEnum";
import { rentalService } from "../../../services/rentalService";
import StatusMessage from "../../common/errorMessage";
import { StatusEnum } from "../../common/errorMessage/StatusMessage";
import ModalHeader from "../../common/modal/modalHeader/ModalHeader";
import SubmitButton from "../../common/submitButton";
import InputField from "../../form/inputField";
import { InputFieldType } from "../../form/inputField/InputField";
import styles from "./createRentalModal.module.scss";
import { RentalItemApiRequest } from "../../../services/apiModels/request/CreateRentalItemApiRequest";
import SelectField from "../../form/select";
import Textarea from "../../form/textArea";
import { DealSites } from "../../../services/apiModels/enums/DealSites";
import { showToastrMessage } from "../../../redux/toastrSlice";

interface Props {
	onClose: () => void;
}

function CreateRentalModal(props: Props) {
	let dispatch = useDispatch();
	let [name, setName] = React.useState<string | null>(null);
	let [phone, setPhone] = React.useState<string | null>(null);
	let [email, setEmail] = React.useState<string | null>(null);
	let [payM, setPayM] = React.useState<RentalPaymentMethodEnum>(RentalPaymentMethodEnum.MobilePay);
	let [dealSite, setDealSite] = React.useState<string>(DealSites[0].value);
	let [dealCoupon, setDealCoupon] = React.useState<string | null>(null); // only has a value if Paymethod is deal

	let [nameValidated, setNameValidated] = React.useState(false);
	let [phoneValidated, setPhoneValidated] = React.useState(false);
	let [emailValidated, setEmailValidated] = React.useState(false);
	let [dealCouponValidated, setDealCouponValidated] = React.useState(false);

	let [errorMessage, setErrorMessage] = React.useState("");
	let [isSubmitting, setIsSubmitting] = React.useState(false);

	const selectedProducts = useSelector(selectRentalSelectedProducts);
	const {selectedStartDate, selectedEndDate} = useSelector(selectRentalCalendar);

	let handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setIsSubmitting(true);
		setErrorMessage("");


		rentalService.rentals.createRental({
			emailAddress: email!,
			fullName: name!,
			items: selectedProducts
				.filter(x => x.amount > 0)
				.map<RentalItemApiRequest>(x => {
					return { productId: x.productId, count: x.amount }}),
			paymentMethod: payM,
			dealCoupon: payM === RentalPaymentMethodEnum.DealCoupon ? dealCoupon : null,
			dealSite: payM === RentalPaymentMethodEnum.DealCoupon ? dealSite : null,
			phone: phone!.toString(),
			startDate: selectedStartDate!,
			endDate: selectedEndDate!
		})
			.then(() => {
				dispatch(loadRentalProducts());
				dispatch(loadRentals());
				dispatch(calendarSetSelectedStartDate(null));
				dispatch(calendarSetSelectedEndDate(null));
				props.onClose();
				dispatch(showToastrMessage("Booking gennemført", "Tak for din bestilling. Vi sender dig en bekræftelses-email med yderligere instrukser."));
				window.scrollTo(0, 0); // quick fix
			})
			.catch((error) => {
				setErrorMessage("Noget gik galt i forsøget på at oprette udlejningen. Prøv evt igen.");
				setIsSubmitting(false);
			})
	};

	let isValidated = () =>
		nameValidated &&
		emailValidated &&
		phoneValidated &&
		(payM !== RentalPaymentMethodEnum.DealCoupon || !!dealCouponValidated);

	return (
		<>
			<ModalHeader title="Book udstyr" />
			<form onSubmit={handleFormSubmit}>
				<div className={styles.input}>
					<InputField
						label="Fulde navn"
						placeholder="Flemming Andersen..."
						name="name"
						value={name ?? ""}
						minLength={1}
						type={InputFieldType.text}
						onChangeHandler={(event) => setName(event.target.value)}
						setIsValid={(isValid) => setNameValidated(isValid)}
					/>
				</div>

				<div className={styles.input}>
					<InputField
						label="Telefonnummer"
						placeholder="88888888..."
						name="phone"
						value={phone ?? ""}
						minLength={1}
						type={InputFieldType.text}
						onChangeHandler={(event) => setPhone(event.target.value)}
						setIsValid={(isValid) => setPhoneValidated(isValid)}
					/>
				</div>

				<div className={styles.input}>
					<InputField
						label="E-mailadresse"
						name="email"
						placeholder="flemming123@gmail.com..."
						value={email ?? ""}
						minLength={1}
						type={InputFieldType.email}
						onChangeHandler={(event) => setEmail(event.target.value)}
						setIsValid={(isValid) => setEmailValidated(isValid)}
					/>
				</div>

				<div className={styles.input}>
					<SelectField
						label="Betalingsmetode"
						name="paymentMethod"
						options={[
							{title: TranslatePaymentMethod(RentalPaymentMethodEnum[RentalPaymentMethodEnum.MobilePay]), value: RentalPaymentMethodEnum.MobilePay},
							{title: TranslatePaymentMethod(RentalPaymentMethodEnum[RentalPaymentMethodEnum.DealCoupon]), value: RentalPaymentMethodEnum.DealCoupon},
						]}
						value={payM}
						onChangeHandler={value => setPayM(parseInt(value))}
					/>

					{payM === RentalPaymentMethodEnum.DealCoupon && (
						<div>
							<SelectField
								label="Deal-site"
								name="dealSite"
								options={DealSites.map(x => {
									return {title: x.displayName, value: x.value}
								})}
								value={dealSite}
								onChangeHandler={value => setDealSite(value)}
							/>

							<div className={styles.dealCouponTextArea}>
								<Textarea
									label={`Indtast ${DealSites.find(x => x.value === dealSite)!.codeWord}`}
									name="dealCoupon"
									value={dealCoupon || ""}
									onChangeHandler={e => setDealCoupon(e.target.value)}
									setIsValid={(isValid) => setDealCouponValidated(isValid)}
								/>
							</div>
						</div>
					)}
				</div>


				<div className={`${styles.content_end} ${styles.button}`}>
					<SubmitButton
						disabled={!isValidated() || isSubmitting}
						submitting={isSubmitting}
						title="Book nu"
					/>
					{!!errorMessage &&
						<StatusMessage text={errorMessage} status={StatusEnum.error} ></StatusMessage>}
				</div>
			</form>
		</>
	);
}

export default CreateRentalModal;
