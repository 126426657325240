import { useState } from "react";
import styles from "./accordion.module.scss";

interface Props {
	title: string;
	content: string;
}

function AccordionSection(props: Props) {
	const [opened, setOpened] = useState(false);

	return (
		<div className={styles.accordion} data-opened={opened}>
			<div className={styles.header} onClick={() => setOpened(!opened)}>
				<span>{props.title}</span>
				<span className={`material-icons ${styles.icon}`}>expand_more</span>
			</div>
			<div className={styles.content} dangerouslySetInnerHTML={{__html: props.content}}></div>
		</div>
	);
}

export default AccordionSection;
