import styles from "./home.module.scss";
import About from "../../components/about";
import Introduction from "../../components/introduction";
import { useContentPage } from "../../redux/contentPagesSlice";

interface Props{
	pageKey: string;
}

function Home(props: Props) {
	useContentPage(props.pageKey);
	return (
		<main className={styles.home}>
			<Introduction pageKey={props.pageKey} ></Introduction>
			<About pageKey={props.pageKey}></About>
		</main>
	);
}

export default Home;
