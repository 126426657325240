export enum RentalPaymentMethodEnum {
	Unknown,
	MobilePay,
	BankTransfer,
	DealCoupon
}

export function TranslatePaymentMethod(pm: string){
	switch (pm) {
		case RentalPaymentMethodEnum[RentalPaymentMethodEnum.Unknown]:
			return "Ukendt";
		case RentalPaymentMethodEnum[RentalPaymentMethodEnum.BankTransfer]:
			return "Bankoverførsel";
		case RentalPaymentMethodEnum[RentalPaymentMethodEnum.MobilePay]:
			return "MobilePay";
		case RentalPaymentMethodEnum[RentalPaymentMethodEnum.DealCoupon]:
			return "Deal-bevis";
		default:
			throw new Error(`Couldnt translate paymentmethod: ${pm}`);
	}
};