
import styles from "./notFound.module.scss";

function NotFound() {
	return (
		<main className={styles.wrapper}>
			<h1>404: Siden findes ikke</h1>
		</main>
	);
}

export default NotFound;
