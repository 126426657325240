import React, { FormEvent, useEffect } from "react";
import styles from "./adminForgotPasswordForm.module.scss";
import InputField from "../../../../components/form/inputField";
import { InputFieldType } from "../../../../components/form/inputField/InputField";
import SubmitButton from "../../../../components/common/submitButton";
import { accountService } from "../../../../services/accountService";
import StatusMessage from "../../../../components/common/errorMessage";
import { StatusEnum } from "../../../../components/common/errorMessage/StatusMessage";

function AdminForgotPasswordForm() {
	let [email, setEmail] = React.useState("");
	let [emailValidated, setEmailValidated] = React.useState(false);
	let [isTempLockedOut, setIsTempLockedOut] = React.useState(false); // used to avoid spamming send
	let [isSubmitting, setIsSubmitting] = React.useState(false);
	let [errorMessage, setErrorMessage] = React.useState("");
	let [successMessage, setSuccessMessage] = React.useState("");

	let handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setIsSubmitting(true);
		setErrorMessage("");
		setSuccessMessage("");

		accountService
			.resetPassword(email)
			.then(() => {
				setSuccessMessage("Der blev sendt en email med instrukser til oprettelse af ny adgangskode!");
				setEmail("");
			})
			.catch((error) => setErrorMessage("Det lykkedes ikke at finde din email-adresse. Har du tastet den rigtigt?"))
			.finally(() => {
				setIsTempLockedOut(true);
				setIsSubmitting(false);
			});
	};

	useEffect(() => {
		if (isTempLockedOut === false) return;

		let timeout = setTimeout(() => {
			setIsTempLockedOut(false);
		}, 3000);

		return () => clearTimeout(timeout);
	}, [isTempLockedOut]);

	let isValidated = () => emailValidated && !isTempLockedOut;

	return (
		<div className={styles.main}>
			<form onSubmit={handleFormSubmit}>
				<div>
					<InputField
						label="Email"
						name="email"
						placeholder="flemming123@gmail.com..."
						value={email}
						type={InputFieldType.email}
						onChangeHandler={(event) => setEmail(event.target.value)}
						setIsValid={(isValid) => setEmailValidated(isValid)}
					/>
				</div>
				<div className={styles.button}>
					<SubmitButton
						disabled={!isValidated() || isSubmitting}
						submitting={isSubmitting}
						title="Send"
					/>
				</div>
				<div>{!!successMessage && ( <StatusMessage text={successMessage} status={StatusEnum.successDark} ></StatusMessage> )}</div>
				<div>{!!errorMessage && ( <StatusMessage text={errorMessage} status={StatusEnum.error} ></StatusMessage> )}
				</div>
			</form>
		</div>
	);
}

export default AdminForgotPasswordForm;