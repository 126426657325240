import React, { FormEvent, useEffect } from "react";
import StatusMessage from "../../../components/common/errorMessage";
import { StatusEnum } from "../../../components/common/errorMessage/StatusMessage";
import SubmitButton from "../../../components/common/submitButton";
import { ApiException } from "../../../services/exceptions";
import { rentalService } from "../../../services/rentalService";
import styles from "./rentalFinishedForm.module.scss";

interface Props{
	rentalId: number;
	phone: string;
};

function RentalFinishedForm(props: Props) {
	let [errorMessage, setErrorMessage] = React.useState("");
	let [successMessage, setSuccessMessage] = React.useState("");
	let [isTempLockedOut, setIsTempLockedOut] = React.useState(false);
	let [isSubmitting, setIsSubmitting] = React.useState(false);

	let handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setIsSubmitting(true);
		setErrorMessage("");
		setSuccessMessage("");

		rentalService
			.rentals.finishRental(props.rentalId, props.phone)
			.then(() => setSuccessMessage("Udstyret er nu meldt ledigt! Tak for hjælpen!"))
			.catch((error) => {
				if (error instanceof ApiException === true) {
					var friendlyText = (error as ApiException).getFriendlyErrorTxt();
					if(!!friendlyText){
						setErrorMessage(friendlyText);
						return;
					}
				}
				setErrorMessage("Det lykkedes ikke at melde udstyret ledigt. Prøv evt igen.");
			})
			.finally(() => {
				setIsTempLockedOut(true);
				setIsSubmitting(false);
			});
	};

	useEffect(() => {
		if (isTempLockedOut === false) return;
		let timeout = setTimeout(() => setIsTempLockedOut(false), 3000);
		return () => clearTimeout(timeout);
	}, [isTempLockedOut]);

	return (
		<form onSubmit={handleFormSubmit}>
			<div className={styles.content}>
				<div className={`${styles.content_end} ${styles.button}`}>
					<SubmitButton
						disabled={false}
						submitting={isSubmitting}
						title="Meld udstyr ledigt"
					/>
					{!!errorMessage && (
						<StatusMessage
							text={errorMessage}
							status={StatusEnum.error}
						></StatusMessage>
					)}

					{!!successMessage && (
						<StatusMessage
							text={successMessage}
							status={StatusEnum.successDark}
						></StatusMessage>
					)}
				</div>
			</div>
		</form>
	);
}

export default RentalFinishedForm;
