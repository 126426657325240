import React, { FormEvent, useEffect } from "react";
import styles from "./contactForm.module.scss";
import Textarea from "../textArea";
import InputField from "../inputField";
import SubmitButton from "../../common/submitButton";
import StatusMessage from "../../common/errorMessage";
import { StatusEnum } from "../../common/errorMessage/StatusMessage";
import { InputFieldType } from "../inputField/InputField";
import { emailService } from "../../../services/emailService";

function ContactForm() {
	let [fullName, setFullName] = React.useState("");
	let [fromEmail, setFromEmail] = React.useState("");
	let [comment, setComment] = React.useState("");
	let [fullNameValidated, setFullNameValidated] = React.useState(false);
	let [fromEmailValidated, setFromEmailValidated] = React.useState(false);
	let [commentValidated, setCommentValidated] = React.useState(false);

	let [errorMessage, setErrorMessage] = React.useState("");
	let [successMessage, setSuccessMessage] = React.useState("");
	let [isTempLockedOut, setIsTempLockedOut] = React.useState(false); // used to avoid spamming send
	let [isSubmitting, setIsSubmitting] = React.useState(false);

	let handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setIsSubmitting(true);
		setErrorMessage("");
		setSuccessMessage("");

		emailService
			.sendContactEmail(fullName, fromEmail, comment)
			.then(() => {
				setFullName("");
				setFromEmail("");
				setComment("");
				setSuccessMessage("Din besked blev afsendt!");
			})
			.catch((error) => {
				console.log(error);
				setErrorMessage(
					"Noget gik galt i forsøget på at sende beskeden. Prøv evt igen."
				);
			})
			.finally(() => {
				setIsTempLockedOut(true);
				setIsSubmitting(false);
			});
	};

	useEffect(() => {
		if (isTempLockedOut === false) return;

		let timeout = setTimeout(() => {
			setIsTempLockedOut(false);
		}, 3000);

		return () => clearTimeout(timeout);
	}, [isTempLockedOut]);

	let isValidated = () =>
		fullNameValidated &&
		fromEmailValidated &&
		commentValidated &&
		!isTempLockedOut;

	return (
		<form onSubmit={handleFormSubmit}>
			<div className={styles.content}>
				<div>
					<div className={styles.input}>
						<InputField
							label="Navn"
							placeholder="Flemming Andersen..."
							name="fullName"
							value={fullName}
							type={InputFieldType.text}
							onChangeHandler={(event) =>
								setFullName(event.target.value)
							}
							setIsValid={(isValid) =>
								setFullNameValidated(isValid)
							}
						/>
					</div>
					<div className={styles.input}>
						<InputField
							label="Email"
							name="email"
							placeholder="flemming123@gmail.com..."
							value={fromEmail}
							type={InputFieldType.email}
							onChangeHandler={(event) =>
								setFromEmail(event.target.value)
							}
							setIsValid={(isValid) =>
								setFromEmailValidated(isValid)
							}
						/>
					</div>
				</div>

				<div className={styles.input}>
					<Textarea
						label="Besked"
						placeholder="Hej Aarhus Vandsportscenter..."
						name="comment"
						value={comment}
						onChangeHandler={(event) =>
							setComment(event.target.value)
						}
						setIsValid={(isValid) => setCommentValidated(isValid)}
					/>
				</div>
			</div>

			<div className={styles.content}>
				<div className={`${styles.content_end} ${styles.button}`}>
					<SubmitButton
						disabled={!isValidated() || isSubmitting}
						submitting={isSubmitting}
						title="Send"
					/>
					{!!errorMessage && (
						<StatusMessage
							text={errorMessage}
							status={StatusEnum.error}
						></StatusMessage>
					)}

					{!!successMessage && (
						<StatusMessage
							text={successMessage}
							status={StatusEnum.success}
						></StatusMessage>
					)}
				</div>
			</div>
		</form>
	);
}

export default ContactForm;
