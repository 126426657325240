import { ComponentType } from "react";

export const routes = {
	forside: "/",
	udlejningAflys: "/booking/aflys/:id",
	udlejningDone: "/booking/done/:id/:phone",
	udlejningBooking: "/booking",
	omOs: "/om-os",
	raadgivning: "/raadgivning",
	foredrag: "/foredrag",
	absurd: "/absurd",
	kiteIntro: "/undervisning/kite-intro",
	kitesurfing: "/undervisning/kitesurfing",
	windsurfing: "/undervisning/windsurfing",
	garnfiskeri: "/undervisning/garnfiskeri",
	selvforsvar: "/undervisning/selvforsvar",
	kajak: "/kajak",
	barrelSauna: "/sauna-toende",
	vildmarksbad: "/vildmarksbad",
	shelter: "/shelter",
	kano: "/kano",
	jolle: "/jolle",
	paddle: "/paddle",
	trailer: "/trailer",
	baadtrailer: "/baadtrailer",
	sikkerhed: "/sikkerhed",
	logInd: "/log-ind",
	opdaterAdgangskode: "/opdater-adganskode",
	glemtAdgangskode: "/glemt-adganskode",
	kontakt: "/kontakt",

	adminMain: "/admin",
	adminRentalProducts: "/admin/udlejning-produkter",
	adminRentals: "/admin/udlejninger",
};

export const genericRoutesComponentsMap:
	{
		path: string,
		pageKey: string,
		productId?: number,
		secondaryProductId?: number,
		disableTopSection?: boolean
		disableImg?: boolean,
		image?: string,
		smallImg?: boolean,
		component?: ComponentType<any>
	}[] = [
		{ path: routes.kiteIntro, pageKey: "kite-intro" },
		{ path: routes.kitesurfing, pageKey: "kitesurfing" },
		{ path: routes.windsurfing, pageKey: "windsurfing" },
		{ path: routes.garnfiskeri, pageKey: "garnfiskeri" },
		{ path: routes.selvforsvar, pageKey: "selvforsvar" },
		{ path: routes.kajak, pageKey: "kajak", productId: 1, secondaryProductId: 2 }, //kajak + dobbeltkajak
		{ path: routes.kano, pageKey: "kano", productId: 3 },
		{ path: routes.jolle, pageKey: "jolle", productId: 4 },
		{ path: routes.paddle, pageKey: "paddle", productId: 5 },
		{ path: routes.trailer, pageKey: "trailer", productId: 6 },
		{ path: routes.barrelSauna, pageKey: "barrel-sauna", productId: 11 },
		{ path: routes.vildmarksbad, pageKey: "vildmarksbad", productId: 12 },
		{ path: routes.shelter, pageKey: "shelter", image: "shelter.jpg", productId: 13 },
		{ path: routes.baadtrailer, pageKey: "baadtrailer", productId: 7 },
		{ path: routes.sikkerhed, pageKey: "sikkerhed", disableImg: true },
		{ path: routes.omOs, pageKey: "om-os", smallImg: true },
		{ path: routes.raadgivning, pageKey: "raadgivning", disableImg: true },
		{ path: routes.foredrag, pageKey: "foredrag", smallImg: true },
		{ path: routes.absurd, pageKey: "absurd", disableImg: true },
	];