import { useDispatch, useSelector } from "react-redux";
import { closeModal, selectModal } from "../../redux/modalSlice";
import CreateRentalModal from "./createRentalModal";
import EditContentSectionModal from "./editContentSectionModal";
import EditRentalProductModal from "./editRentalProductModal";
import EditRentalProductPriceModal from "./editRentalProductPriceModal";
import styles from "./modalWrapper.module.scss";

function ModalWrapper() {
	const modal = useSelector(selectModal);
	const dispatch = useDispatch();
	const close = () => dispatch(closeModal());

	return (
		<>
			{!!modal.show && (
				<div className={styles.main} onClick={close}>
					<div
						className={styles.content}
						onClick={(e) => e.stopPropagation()}
					>
						<span
							className={`material-icons ${styles.closeButton}`}
							onClick={close}
						>
							close
						</span>
						{
							!!modal.body?.contentPage ? <EditContentSectionModal onClose={close} /> :
							!!modal.body?.rentalProduct ? <EditRentalProductModal onClose={close} /> :
							!!modal.body?.createRental ? <CreateRentalModal onClose={close} /> :
							!!modal.body?.rentalProductPrice ? <EditRentalProductPriceModal onClose={close} /> : <></>
						}
					</div>
				</div>
			)}
		</>
	);
}

export default ModalWrapper;
