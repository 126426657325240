import styles from "./rentalTableHealder.module.scss";

function RentalTableHeader() {
	return (
		<div className={styles.table_row}>
			<div className={styles.table_cell}>
				<span>ID</span>
			</div>
			<div className={styles.table_cell}>
				<span>Kunde</span>
			</div>
			<div className={styles.table_cell}>
				<span>Produkter</span>
			</div>
			<div className={styles.table_cell}>
				<span>Betalingsmetode</span>
			</div>
			<div className={styles.table_cell}>
				<span>Periode</span>
			</div>
			<div className={styles.table_cell}>
				<span>Administrator-kommentar</span>
			</div>
		</div>
	);
}

export default RentalTableHeader;
