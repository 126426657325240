import styles_dark from "./dark.module.scss";
import styles_light from "./light.module.scss";

export enum SpinnerTheme {
	Dark = "Dark",
	Light = "Light"
}

const styles = {
	Dark: styles_dark,
	Light: styles_light
}

interface Props {
	theme: SpinnerTheme,
	hideText?: boolean
}

function Spinner(props: Props) {
	return (
		<div className={styles[props.theme].container}>
			<div className={styles[props.theme].lds_dual_ring}></div>
			{props.hideText != true && (
				<p className={styles[props.theme].text}>Indlæser...</p>
			)}
		</div>
	);
}

export default Spinner;
