import styles from "./label.module.scss";

interface Props {
	label: string;
	inputName: string;
}

function Label(props: Props) {
	return (
		<label className={styles.label} htmlFor={props.inputName}>
			{props.label}
		</label>
	);
}

export default Label;
