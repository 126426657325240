import { httpService } from "./httpService";
import { apiEndpoints } from "../config/apiConfig";
import { AccountLoginApiResponse } from "./apiModels/response/AccountLoginApiResponse";

export const accountService = {
	login: async (email: string, password: string): Promise<AccountLoginApiResponse> => {
		return await httpService.post<AccountLoginApiResponse>(
			apiEndpoints.accounts.login,
			{ email, password });
	},

	resetPassword: async (email: string): Promise<any> => {
		return await httpService.post<any>(
			apiEndpoints.accounts.resetPassword,
			{ email });
	},

	updatePassword: async (resetPasswordToken: string, password: string): Promise<any> => {
		return await httpService.put<any>(
			apiEndpoints.accounts.updatePassword,
			{ resetPasswordToken, password });
	}
}
