import { useSelector } from "react-redux";
import { routes } from "../../config";
import { selectContentPageSection } from "../../redux/contentPagesSlice";
import { Button, ButtonTheme } from "../common";
import { EditableContent } from "../common/editableStuff";
import styles from "./introduction.module.scss";
import { ReactComponent as Wave } from "./wave.svg";

interface Props {
	pageKey: string;
}

function Introduction(props: Props) {
	let section_intro = useSelector(selectContentPageSection(props.pageKey, "intro"));

	return (
		<section className={styles.introduction}>
			<div className={styles.contentWrapper}>
				{!!section_intro && (
					<div className={styles.content}>
						<EditableContent
							pageKey={props.pageKey}
							sectionKey={"intro"}
						>
							<h1 className={styles.heading}>
								{section_intro.title}
							</h1>
							<p dangerouslySetInnerHTML={{__html: section_intro.content}}></p>
							<div>
								<Button
									path={routes.udlejningBooking}
									text="Book"
									theme={ButtonTheme.Dark}
								></Button>
								<Button
									path={routes.kontakt}
									text="Kontakt"
									theme={ButtonTheme.Light}
								></Button>
							</div>
						</EditableContent>
					</div>
				)}
			</div>

			<div className={styles.wave}>
				<Wave />
				<div className={styles.waveBottom}></div>
			</div>
		</section>
	);
}

export default Introduction;
