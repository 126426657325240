import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { RentalApiResponse } from '../services/apiModels/response/RentalApiResponse';
import { RentalCategoryApiResponse } from '../services/apiModels/response/RentalCategoryApiResponse';
import { RentalDetailedApiResponse } from '../services/apiModels/response/RentalDetailedApiResponse';
import { RentalProductApiResponse } from '../services/apiModels/response/RentalProductApiResponse';
import { RentalStatisticsApiResponse } from '../services/apiModels/response/RentalStatisticsApiResponse';
import { RentalStatisticsItemApiResponse } from '../services/apiModels/response/RentalStatisticsItemApiResponse';
import { ApiException } from '../services/exceptions';
import { rentalService } from '../services/rentalService';
import { yyyyMMdd, parseDate, dateMs, addDays, daysArray } from '../utility';
import { LoadingStatusEnum } from './loadingStatusEnum';
import { AppThunk, RootState } from './store';

interface RentalStateProductsData{
	loadingStatus: LoadingStatusEnum;
	products: RentalProductApiResponse[];
	errorMessage: string | null;
}

interface RentalCalendarState{
	selectedStartDate: string | null,
	selectedEndDate: string | null,
	currentMonth: number,
	currentYear: number
}

export interface RentalStateSelectedProduct {
	productId: number;
	amount: number;
}

interface RentalStateRentalsData {
	loadingStatus: LoadingStatusEnum;
	errorMessage: string | null;
	rentals: RentalApiResponse[];
}

interface RentalStateRentalsDetailedData {
	loadingStatus: LoadingStatusEnum;
	errorMessage: string | null;
	rentals: RentalDetailedApiResponse[];
}

interface RentalStateRentalStatistics {
	loadingStatus: LoadingStatusEnum;
	errorMessage: string | null;
	items: RentalStatisticsItemApiResponse[];
}

interface RentalStateRentalCategories {
	loadingStatus: LoadingStatusEnum;
	errorMessage: string | null;
	categories: RentalCategoryApiResponse[];
}

interface RentalState {
	selectedProducts: RentalStateSelectedProduct[],
	rentalsData: RentalStateRentalsData,
	rentalsDetailedData: RentalStateRentalsDetailedData,
	rentalStatistics: RentalStateRentalStatistics,
	rentalCategories: RentalStateRentalCategories,
	productsData: RentalStateProductsData,
	calendar: RentalCalendarState
}

const initialState: RentalState = {
	rentalsData: {
		loadingStatus: LoadingStatusEnum.NotLoaded,
		errorMessage: null,
		rentals: []
	},
	rentalsDetailedData: {
		loadingStatus: LoadingStatusEnum.NotLoaded,
		errorMessage: null,
		rentals: []
	},
	rentalStatistics: {
		loadingStatus: LoadingStatusEnum.NotLoaded,
		errorMessage: null,
		items: []
	},
	rentalCategories: {
		loadingStatus: LoadingStatusEnum.NotLoaded,
		errorMessage: null,
		categories: []
	},
	selectedProducts: [],
	productsData: {
		loadingStatus: LoadingStatusEnum.NotLoaded,
		products: [],
		errorMessage: null
	},
	calendar: {
		selectedStartDate: null,
		selectedEndDate: null,
		currentMonth: new Date().getMonth(),
		currentYear: new Date().getFullYear()
	}
};

export const rentalSlice = createSlice({
	name: 'rental',
	initialState,
	reducers: {
		loadRentalProductsRequest: state => {
			state.productsData.loadingStatus = LoadingStatusEnum.Loading;
			state.productsData.errorMessage = null;
		},
		loadRentalProductsSuccess: (state, action: PayloadAction<RentalProductApiResponse[]>) => {
			state.productsData.loadingStatus = LoadingStatusEnum.Loaded;
			state.productsData.products = action.payload;
			state.selectedProducts = action.payload.map(x => ({productId: x.id, amount: 0} as RentalStateSelectedProduct));
		},
		loadRentalProductsError: (state, action: PayloadAction<{ errorMsg: string }>) => {
			state.productsData.loadingStatus = LoadingStatusEnum.NotLoaded;
			state.productsData.errorMessage = action.payload.errorMsg;
		},

		loadRentalsRequest: state => {
			state.rentalsData.loadingStatus = LoadingStatusEnum.Loading;
			state.rentalsData.errorMessage = null;
		},
		loadRentalsSuccess: (state, action: PayloadAction<RentalApiResponse[]>) => {
			state.rentalsData.rentals = action.payload;
			state.rentalsData.loadingStatus = LoadingStatusEnum.Loaded;
		},
		loadRentalsError: (state, action: PayloadAction<{errorMsg: string}>) => {
			state.rentalsData.loadingStatus = LoadingStatusEnum.NotLoaded;
			state.rentalsData.errorMessage = action.payload.errorMsg;
		},

		loadRentalStatisticsRequest: state => {
			state.rentalStatistics.loadingStatus = LoadingStatusEnum.Loading;
			state.rentalStatistics.errorMessage = null;
		},
		loadRentalStatisticsSuccess: (state, action: PayloadAction<RentalStatisticsApiResponse>) => {
			state.rentalStatistics.items = action.payload.items;
			state.rentalStatistics.loadingStatus = LoadingStatusEnum.Loaded;
		},
		loadRentalStatisticsError: (state, action: PayloadAction<{errorMsg: string}>) => {
			state.rentalStatistics.loadingStatus = LoadingStatusEnum.NotLoaded;
			state.rentalStatistics.errorMessage = action.payload.errorMsg;
		},

		loadRentalsDetailedRequest: state => {
			state.rentalsDetailedData.loadingStatus = LoadingStatusEnum.Loading;
			state.rentalsDetailedData.errorMessage = null;
		},
		loadRentalsDetailedSuccess: (state, action: PayloadAction<RentalDetailedApiResponse[]>) => {
			state.rentalsDetailedData.rentals = action.payload;
			state.rentalsDetailedData.loadingStatus = LoadingStatusEnum.Loaded;
		},
		loadRentalsDetailedError: (state, action: PayloadAction<{errorMsg: string}>) => {
			state.rentalsDetailedData.loadingStatus = LoadingStatusEnum.NotLoaded;
			state.rentalsDetailedData.errorMessage = action.payload.errorMsg;
		},
		updateRentalDetailed: (state, action: PayloadAction<{id: number, comment: string, categoryId: number}>) => {
			var match = state.rentalsDetailedData.rentals.find(x => x.id === action.payload.id)!;
			match.adminComment = action.payload.comment;
			match.categoryId = action.payload.categoryId;

			var category = state.rentalCategories.categories.find(x => x.id === action.payload.categoryId);
			match.categoryName = category!.name;
		},
		deleteRental: (state, action: PayloadAction<{id: number}>) => {
			state.rentalsData.rentals = state.rentalsData.rentals.filter(x => x.id !== action.payload.id);
			state.rentalsDetailedData.rentals = state.rentalsDetailedData.rentals.filter(x => x.id !== action.payload.id);
		},

		loadRentalCategoriesRequest: state => {
			state.rentalCategories.loadingStatus = LoadingStatusEnum.Loading;
			state.rentalCategories.errorMessage = null;
		},
		loadRentalCategoriesSuccess: (state, action: PayloadAction<RentalCategoryApiResponse[]>) => {
			state.rentalCategories.categories = action.payload;
			state.rentalCategories.loadingStatus = LoadingStatusEnum.Loaded;
		},
		loadRentalCategoriesError: (state, action: PayloadAction<{errorMsg: string}>) => {
			state.rentalCategories.loadingStatus = LoadingStatusEnum.NotLoaded;
			state.rentalCategories.errorMessage = action.payload.errorMsg;
		},

		removeRentalProduct: (state, action: PayloadAction<{id: number}>) => {
			state.productsData.products = state.productsData.products.filter(x => x.id !== action.payload.id);
		},
		removeRentalProductPrice: (state, action: PayloadAction<{productId: number, priceId: number}>) => {
			let product = state.productsData!.products!.find(x => x.id === action.payload.productId)!;
			product.prices = product?.prices.filter(x => x.id !== action.payload.priceId);
		},


		calendarSetSelectedStartDate: (state, action: PayloadAction<Date | null>) =>{
			state.calendar.selectedStartDate = action.payload != null ? yyyyMMdd(action.payload!) : null;
		},
		calendarChangeMonth: (state, action: PayloadAction<number>) => {
			let newMonth = state.calendar.currentMonth += action.payload;
			if(newMonth > 11){
				state.calendar.currentMonth = newMonth % 12;
				state.calendar.currentYear += Math.floor(newMonth/12);
			}
			else if(newMonth < 0){
				state.calendar.currentMonth = newMonth + 12;
				state.calendar.currentYear -= Math.ceil((newMonth*-1)/12);
			}
			else
				state.calendar.currentMonth = newMonth;
		},
		calendarSetSelectedEndDate: (state, action: PayloadAction<Date | null>) =>{
			state.calendar.selectedEndDate = action.payload != null ? yyyyMMdd(action.payload!) : null;
		},
		rentalSetSelectedProduct: (state, action: PayloadAction<{id: number, amount: number}>) => {
			let product = state.selectedProducts.find(x => x.productId === action.payload.id);
			product!.amount = action.payload.amount;
		},
		rentalResetSelectedProducts: (state) => {
			state.selectedProducts.forEach(x => x.amount = 0);
		}
	},
});

export const {
	loadRentalProductsRequest,
	loadRentalProductsSuccess,
	loadRentalProductsError,

	loadRentalsRequest,
	loadRentalsSuccess,
	loadRentalsError,

	loadRentalStatisticsRequest,
	loadRentalStatisticsSuccess,
	loadRentalStatisticsError,

	loadRentalsDetailedRequest,
	loadRentalsDetailedSuccess,
	loadRentalsDetailedError,
	updateRentalDetailed,
	deleteRental,

	loadRentalCategoriesRequest,
	loadRentalCategoriesSuccess,
	loadRentalCategoriesError,

	removeRentalProduct,
	removeRentalProductPrice,

	calendarSetSelectedStartDate,
	calendarSetSelectedEndDate,
	calendarChangeMonth,

	rentalSetSelectedProduct,
	rentalResetSelectedProducts
} = rentalSlice.actions;

// Actions
export const loadRentals = (): AppThunk => async dispatch => {
	dispatch(loadRentalsRequest())

	try {
		let response = await rentalService.rentals.getRentals();
		dispatch(loadRentalsSuccess(response));
	}
	catch (error) {
		let errorMsg = "En fejl skete i forsøget på at indlæse eksisterende udlejninger";
		dispatch(loadRentalsError({ errorMsg: errorMsg }));
	}
};

export const loadRentalsDetailed = (): AppThunk => async dispatch => {
	dispatch(loadRentalsDetailedRequest())

	try {
		let response = await rentalService.rentals.getRentalsDetailed();
		dispatch(loadRentalsDetailedSuccess(response));
	}
	catch (error) {
		let errorMsg = "En fejl skete i forsøget på at indlæse eksisterende udlejninger";
		dispatch(loadRentalsDetailedError({ errorMsg: errorMsg }));
	}
};

export const loadRentalStatistics = (): AppThunk => async dispatch => {
	dispatch(loadRentalStatisticsRequest())

	try {
		let response = await rentalService.rentals.getRentalStatistics();
		dispatch(loadRentalStatisticsSuccess(response));
	}
	catch (error) {
		let errorMsg = "En fejl skete i forsøget på at indlæse udlejningsstatistik";
		dispatch(loadRentalStatisticsError({ errorMsg: errorMsg }));
	}
};

export const loadRentalProducts = (): AppThunk => async dispatch => {
	dispatch(loadRentalProductsRequest());

	try {
		let response = await rentalService.products.getRentalProducts();
		dispatch(loadRentalProductsSuccess(response));
	}
	catch (error) {
		let errorMsg = "En fejl skete i forsøget på at indlæse produkter";
		dispatch(loadRentalProductsError({ errorMsg: errorMsg }));
	}
};

export const loadRentalCategories = (): AppThunk => async dispatch => {
	dispatch(loadRentalCategoriesRequest());

	try {
		let response = await rentalService.categories.getRentalCategories();
		dispatch(loadRentalCategoriesSuccess(response));
	}
	catch (error) {
		let errorMsg = "En fejl skete i forsøget på at indlæse kategorier";
		dispatch(loadRentalCategoriesError({ errorMsg: errorMsg }));
	}
};

export const deleteRentalProduct = (id: number): AppThunk => async dispatch => {
	try{
		await rentalService.products.deleteRentalProduct(id);
		dispatch(removeRentalProduct({id}));
	}
	catch(error){
		if (error instanceof ApiException === true) {
			let friendlyText = (error as ApiException).getFriendlyErrorTxt();
			if(!!friendlyText){
				window.alert(friendlyText);
				return;
			}
		}

		throw error;
	}
};

export const deleteRentalProductPrice = (productId: number, priceId: number): AppThunk => async dispatch => {
	await rentalService.productPrices.deleteRentalProduct(productId, priceId);
	dispatch(removeRentalProductPrice({productId, priceId}));
};

// custom hooks
export function useRentalCategories(){
	let categoriesState = useSelector((state: RootState) => state.rentals.rentalCategories);
	let dispatch = useDispatch();

	// load stuff if not already loading/loaded
	if(!categoriesState.categories.length && categoriesState.loadingStatus === LoadingStatusEnum.NotLoaded)
		dispatch(loadRentalCategories());

	return categoriesState;
}

export function useRentalProducts(){
	let rentalProductState = useSelector((state: RootState) => state.rentals.productsData);
	let dispatch = useDispatch();

	// load stuff if not already loading/loaded
	if(!rentalProductState.products.length && rentalProductState.loadingStatus === LoadingStatusEnum.NotLoaded)
		dispatch(loadRentalProducts());

	return rentalProductState;
}

// Selectors
export const selectRentalCalendar = (state: RootState) => {
	let start = state.rentals.calendar.selectedStartDate;
	let end = state.rentals.calendar.selectedEndDate;
	return {
		selectedStartDate: start != null ? parseDate(start) : null,
		selectedEndDate: end != null ? parseDate(end) : null,
		currentMonth: state.rentals.calendar.currentMonth,
		currentYear: state.rentals.calendar.currentYear
	};
};

/**
 * A nasty method, that creates a date array between two dates and figures out which products and how many are available each day.
 * Good luck trying to make sense of it.
 */
export const selectAvailableProductsByDate = (startDate: Date, endDate: Date) => (state: RootState) => {
	let rentals = state.rentals.rentalsData.rentals;
	let products = state.rentals.productsData.products;
	let days = daysArray(startDate, endDate)
		.map(x => {
			return {
				date: yyyyMMdd(x),
				availableProducts: products.map(x => {
					return {
						productId: x.id,
						amount: x.amountInStock
					}
				})
			}
		});

	let currDate = startDate;
	while(dateMs(currDate) <= dateMs(endDate)){
		let currDateMs = dateMs(currDate);
		let rentalsOnDate = rentals.filter(x =>{
			let startDateMs = dateMs(parseDate(x.startDate));
			let endDateMs = dateMs(parseDate(x.endDate));

			return startDateMs <= currDateMs && endDateMs >= currDateMs;
		});

		rentalsOnDate.forEach(rental => {
			rental.items.forEach(rentalItem => {
				let day = days.find(x => x.date === yyyyMMdd(currDate));
				let availableProduct = day!.availableProducts.find(x => x.productId === rentalItem.productId);
				availableProduct!.amount -= rentalItem.count
			});
		});



		addDays(currDate, 1);
	}

	return days;
}

export const selectRentalSelectedProducts = (state: RootState) => {
	return state.rentals.selectedProducts;
}

export const selectRentalSDetailed = (state: RootState) => {
	return state.rentals.rentalsDetailedData;
}

export const selectRentalStatistics = (state: RootState) => {
	return state.rentals.rentalStatistics;
}

export default rentalSlice.reducer;