import { useSelector } from "react-redux";
import { selectRentalCalendar } from "../../redux/rentalSlice";
import styles from "./calendar.module.scss";
import CalendarBody from "./calendarBody";
import CalendarHeader from "./calendarHeader";

interface Props {
	disabled: boolean;
	disabledDates: Date[];
}

function Calendar(props: Props) {
	const { selectedStartDate, selectedEndDate } =
		useSelector(selectRentalCalendar);

	return (
		<div className={styles.main}>
			{props.disabled && (
				<div className={styles.calendar_overlay}>
					<p className={styles.calendar_overlay_text}>Vælg udstyr først</p>
				</div>
			)}

			<div className={styles.calendar}>
				<CalendarHeader />
				<CalendarBody disabledDates={props.disabledDates}/>
			</div>

			<div className={styles.dates}>
				<p>
					<span className={styles.dates__from}>
						{selectedStartDate?.toLocaleDateString() ?? "Fra"}
					</span>
					{" "}-{" "}
					<span className={styles.dates__to}>
						{selectedEndDate?.toLocaleDateString() ?? "Til"}
					</span>
				</p>
			</div>
			{selectedStartDate && !selectedEndDate && (
				<p className={styles.datesHint}>Hvis du blot vil leje udstyret en enkelt dag, skal du klikke to gange på datoen således at både fra-dato og til-dato er ens.</p>
			)}
		</div>
	);
}

export default Calendar;
