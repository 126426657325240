import { ChangeEvent, useEffect, useState } from "react";
import styles from "./inputField.module.scss";
import Label from "../label";

export enum InputFieldType {
	text,
	email,
	password,
	number
}

interface Props {
	errorMessage?: string;
	value?: string | number;
	placeholder?: string;
	minLength?: number;
	maxLength?: number;
	minValue?: number;
	maxValue?: number;
	label: string;
	name: string;
	type: InputFieldType;
	onChangeHandler: (event: ChangeEvent<HTMLInputElement>) => void;
	setIsValid?: (isValid: boolean) => void;
}

const validateText = (value: string, minLength: number, maxLength: number) =>
	value.length >= minLength && value.length <= maxLength;
const validateNumber = (value: number, minValue: number, maxValue: number) =>
	value >= minValue && value <= maxValue;
const validateEmail = (value: string) => {
	var emailRegex = /\S+@\S+\.\S+/;
	return value.length > 0 && emailRegex.test(value);
};

function InputField(props: Props) {
	const [isValid, setIsValid] = useState(false);

	// trigger an initial validation. otherwise it wont happen before onchange
	useEffect(() => {
		validate(props.value!);
	}, [props.value]);

	const onChange = (event: ChangeEvent<HTMLInputElement>) => {
		props.onChangeHandler(event);
		validate(event.target.value);
	};

	const validate = (value: string | number) => {
		let tmpIsValid: boolean;
		if (props.type === InputFieldType.text)
			tmpIsValid = validateText(value as string, props.minLength ?? 1, props.maxLength ?? 9999999);
		else if (props.type === InputFieldType.password)
			tmpIsValid = validateText(value as string, props.minLength ?? 1, props.maxLength ?? 250);
		else if (props.type === InputFieldType.email)
			tmpIsValid = validateEmail(value as string);
		else if (props.type === InputFieldType.number)
			tmpIsValid = validateNumber(value as number, props.minValue ?? 1, props.maxValue ?? 9999999);
		else throw new Error(`${InputFieldType[props.type]} not implemented`);

		if (tmpIsValid !== isValid) {
			setIsValid(tmpIsValid);
			props.setIsValid && props.setIsValid(tmpIsValid);
		}
	};

	return (
		<div className={styles.field}>
			<Label inputName={props.name} label={props.label}></Label>
			<input
				className={styles.input}
				id={props.name}
				type={InputFieldType[props.type]}
				placeholder={props.placeholder}
				name={props.name}
				value={props.value || ""}
				onChange={onChange}
			/>
		</div>
	);
}

export default InputField;
