import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Spinner } from "../../../components/common";
import { SpinnerTheme } from "../../../components/common/spinner/Spinner";
import SubmitButton from "../../../components/common/submitButton";
import { routes } from "../../../config";
import { selectIsAuthorized } from "../../../redux/authSlice";
import { LoadingStatusEnum } from "../../../redux/loadingStatusEnum";
import { openModal_rentalProduct, openModal_rentalProductPrice } from "../../../redux/modalSlice";
import {
	useRentalProducts,
} from "../../../redux/rentalSlice";
import RentalProduct from "./components/rentalProduct";
import RentalProductPrice from "./components/rentalProductPrice";
import styles from "./rentalProducts.module.scss";

interface Props extends RouteComponentProps {}

export default function RentalProducts(props: Props) {
	const isAuthorized = useSelector(selectIsAuthorized);
	const rentalProductsState = useRentalProducts();
	const dispatch = useDispatch();

	useEffect(() => {
		!isAuthorized && props.history.push(routes.logInd);
	}, [isAuthorized]);

	return (
		<div className={styles.main}>
			<h1 className={styles.h1}>Produkter til udlejning</h1>
			{rentalProductsState.loadingStatus !== LoadingStatusEnum.Loaded ? (
				<Spinner theme={SpinnerTheme.Dark} />
			) : (
				<>
					<div className={styles.create_product_button}>
						<SubmitButton disableChevron={true} title="Opret produkt" onClick={() => dispatch(openModal_rentalProduct({ body: { type: "create" } }))}/>
					</div>
					<div className={styles.product_container}>
						{rentalProductsState.products.map((product) => (
							<div key={product.id} className={styles.product}>
								<RentalProduct product={product} />
								<div>
									{product.prices.map((price) => <RentalProductPrice productId={product.id} price={price} key={price.id}/> )}
									<SubmitButton disableChevron={true} title="Opret pris" onClick={() => dispatch(openModal_rentalProductPrice({ body: { type: "create", productId: product.id } }))}/>
								</div>
							</div>
						))}
					</div>
				</>
			)}
		</div>
	);
}