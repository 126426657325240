import { useState } from "react";
import { RentalProductApiResponse } from "../../../services/apiModels/response/RentalProductApiResponse";
import styles from "./accordion.module.scss";

interface Props {
	title: string;
	product: RentalProductApiResponse;
}

function AccordionPrice(props: Props) {
	const [opened, setOpened] = useState(true);

	return (
		<div className={styles.accordion} data-opened={opened}>
			<div className={styles.header} onClick={() => setOpened(!opened)}>
				<span>{props.title}</span>
				<span className={`material-icons ${styles.icon}`}>expand_more</span>
			</div>
			<div className={styles.content}>
				{props.product?.prices.map(price =>
					<div className={styles.prices}>
						<span className={`material-icons ${styles.priceIcon}`}>sell</span>
						<div className={styles.price}>
							<div>Antal/dage: {price.quantity}</div>
							<div><strong>{price.unitPrice} DKK</strong> pr. stk./dag</div></div>
						</div>
				)}
			</div>
		</div>
	);
}

export default AccordionPrice;
