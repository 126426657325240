import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

interface MobileMenuState {
	isOpen: boolean,
}

const initialState: MobileMenuState = {
	isOpen: false,
};

// Actions + reducers
export const mobileMenuSlice = createSlice({
	name: 'mobileMenu',
	initialState,
	reducers: {
		toggleMenu: (state, action: PayloadAction<{ isOpen: boolean }>) => {
			state.isOpen = action.payload.isOpen;
		}
	},
});

export const { toggleMenu } = mobileMenuSlice.actions;


// Selectors
export const selectMobileMenuIsOpen = (state: RootState) => {
	return state.mobileMenu.isOpen;
};

export default mobileMenuSlice.reducer;