import styles from "./statusMessage.module.scss";

export enum StatusEnum {
	success,
	successDark,
	error,
}

interface Props {
	text: string;
	status: StatusEnum;
}

function StatusMessage(props: Props) {
	return <p className={styles[StatusEnum[props.status]]}>{props.text}</p>;
}

export default StatusMessage;
