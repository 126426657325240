import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectIsAuthorized } from "../../../../redux/authSlice";
import { openModal_rentalProductPrice } from "../../../../redux/modalSlice";
import { deleteRentalProductPrice } from "../../../../redux/rentalSlice";
import styles from "../editableStuff.module.scss";

interface Props {
	children: React.ReactNode;
	productId: number;
	priceId: number;
}

function EditableRentalProductPrice(props: Props) {
	const isAuthorized = useSelector(selectIsAuthorized);

	var dispatch = useDispatch();

	const handleEditClick = () =>
		dispatch(
			openModal_rentalProductPrice({
				body: {
					productId: props.productId,
					priceId: props.priceId,
					type: "update",
				},
			})
		);

	const handleDeleteClick = () => {
		if (window.confirm("Er du sikker på at du vil slette?"))
			dispatch(deleteRentalProductPrice(props.productId, props.priceId));
	};

	return (
		<div className={styles.editableContent}>
			{!!isAuthorized && (
				<>
					<span
						onClick={handleEditClick}
						title="Ændr pris"
						className={`${styles.button} material-icons`}
						style={{ marginLeft: "-50px" }}
					>
						edit
					</span>
					<span
						onClick={handleDeleteClick}
						title="Slet produkt"
						className={`${styles.button} material-icons`}
						style={{ marginLeft: "-25px" }}
					>
						delete
					</span>
				</>
			)}
			{props.children}
		</div>
	);
}

export default EditableRentalProductPrice;
