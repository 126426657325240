export const apiBaseUrl = process.env.REACT_APP_API_BASEURL;
export const apiEndpoints = {
	accounts: {
		login: `accounts/login`,
		resetPassword: `accounts/resetPassword`,
		updatePassword: `accounts/updatePassword`
	},
	system: {
		postContactMessage: `system/sendContactEmail`,
	},
	contentPages: {
		getContentPage: `contentPages/{key}`,
		updateContentPageSection: `contentPages/{key}/sections/{sectionKey}`
	},
	rentalProducts: {
		getRentalProducts: `rentalProducts`,
		createRentalProduct: `rentalProducts`,
		updateRentalProduct: `rentalProducts/{id}`,
		deleteRentalProduct: `rentalProducts/{id}`
	},
	rentalCategories: {
		getRentalCategories: `rentalCategories`
	},
	rentalProductPrices: {
		createRentalProductPrice: `rentalProducts/{id}/prices`,
		updateRentalProductPrice: `rentalProducts/{id}/prices/{priceId}`,
		deleteRentalProductPrice: `rentalProducts/{id}/prices/{priceId}`
	},
	rentals: {
		getRentals: `rentals`,
		getRental: `rentals/{id}`,
		getRentalsDetailed: `rentals/detailed`,
		getRentalStatistics: `rentals/statistics`,
		createRental: `rentals`,
		updateRental: `rentals/{id}`,
		finishRental: `rentals/{id}/finish?phone={phone}`,
		deleteRental: `rentals/{id}?phone={phone}`,
	}
};