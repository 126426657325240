import { useSelector } from "react-redux";
import { routes } from "../../config";
import { selectContentPageSection } from "../../redux/contentPagesSlice";
import { ContentTile } from "../common";
import { EditableContent } from "../common/editableStuff";
import styles from "./about.module.scss";

interface Props {
	pageKey: string;
}

function Introduction(props: Props) {
	let section_undervisning = useSelector(selectContentPageSection(props.pageKey, "undervisning"));
	let section_udlejning = useSelector(selectContentPageSection(props.pageKey, "udlejning"));
	let section_events = useSelector(selectContentPageSection(props.pageKey, "events"));

	return (
		<section className={styles.about}>
			<h3>Vi tilbyder</h3>
			<div className={styles.column}>
				{!!section_undervisning &&
					<EditableContent
						pageKey={props.pageKey}
						sectionKey={"undervisning"}
					>
						{/* TODO: Add link */}
						<ContentTile
							heading={section_undervisning!.title}
							text={section_undervisning!.content} />
					</EditableContent>
				}
				{!!section_undervisning &&
					<EditableContent
						pageKey={props.pageKey}
						sectionKey={"udlejning"}
					>
						{/* TODO: Add link */}
						<ContentTile
							heading={section_udlejning!.title}
							text={section_udlejning!.content} />
					</EditableContent>
				}
				{!!section_undervisning &&
					<EditableContent
						pageKey={props.pageKey}
						sectionKey={"events"}
					>
						<ContentTile
							heading={section_events!.title}
							text={section_events!.content} />
					</EditableContent>
				}
			</div>
		</section>
	);
}

export default Introduction;
