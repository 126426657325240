import styles from "./selectField.module.scss";
import Label from "../label";

interface Props {
	options: {title: string, value: string | number}[];
	label: string | null;
	name: string;
	value: string | number;
	onChangeHandler: (value: string) => void;
}

function SelectField(props: Props) {
	return (
		<div className={styles.field}>
			{props.label && (
				<Label inputName={props.name} label={props.label}></Label>
			)}
			<div className={styles.wrap}>
				<select
					className={styles.select}
					name={props.name}
					id={props.name}
					onChange={(e) => props.onChangeHandler(e.target.value)}
					value={props.value}
				>
					{props.options.map((option) => (
						<option key={`${option.value}`} value={option.value}>
							{option.title}
						</option>
					))}
				</select>
			</div>
		</div>
	);
}

export default SelectField;
