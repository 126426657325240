import { httpService } from "./httpService";
import { apiEndpoints } from "../config/apiConfig";

export const emailService = {
	sendContactEmail: async (fullName: string, fromEmail: string, comment: string): Promise<any> => {
		return await httpService.post<any>(
			apiEndpoints.system.postContactMessage,
			{
				fullName: fullName,
				fromEmail: fromEmail,
				comment: comment,
			});
	}
}
