import styles from "../button/dark.module.scss";

interface Props {
	title: string;
	submitting?: boolean;
	disabled?: boolean;
	onClick?: () => void;
	disableChevron?: boolean;
}

function SubmitButton(props: Props) {
	return (
		<button
			disabled={!!props.disabled}
			type="submit"
			className={`${styles.button} ${styles.slim} ${
				!!props.submitting && "submitting"
			}`}
			onClick={props.onClick}
		>
			<span className={styles.content}>
				{!!props.submitting ? "Gemmer" : props.title}
				{!props.disableChevron &&
					<span className={`material-icons`}>chevron_right</span>
				}
			</span>
		</button>
	);
}

export default SubmitButton;
